<template>
  <div class="noti">
    <div v-for="(item, idx) in notifications" :key="idx">
      <b-card :title="item.title">
        <b-card-text>
          {{ item.detail }}
        </b-card-text>

        <router-link :to="item.redirect" class="card-link"
          >รายละเอียด</router-link
        >
        | {{ convertDateSec(item.created_at) }}
      </b-card>
    </div>
  </div>
</template>

<script>
import memberService from "@/services/Member";
import moment from "moment";

export default {
  name: "notifications-page",
  data() {
    return {
      notifications: [],
    };
  },
  created() {
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      memberService
        .notifications()
        .then((res) => {
          this.notifications = res.data;
        })
        .catch({});
    },
    convertDateSec(date) {
      return moment(date).format("DD/MM/YY HH:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.nofi {
  border: 1px solid gray;
}

.card-body {
  padding: 5px;
  margin: 5px !important;
}
.card {
  margin-bottom: 10px;
  // position: relative;
}
</style>
