<template>
  <div class="form-post">
    <h4>รายละเอียดประมูล</h4>
    <form>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label"
          >ชื่อประกาศ</label
        >
        <div class="col-sm-9">
          <AutoInput :last="lastPost" v-model="form.title"></AutoInput>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">ประเภท</label>
        <div class="col-sm-9">
          <input
            type="text"
            class="form-control"
            @click="showSelectCat = true"
            v-model="selecCatDisplay"
            readonly
            style="cursor:pointer"
          />
        </div>
      </div>

      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label">รูปภาพ</label>
        <div class="col-sm-9">
          <Upload @onUpdate="onSelectFile" :listFile="images"></Upload>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword3" class="col-sm-3 col-form-label"
          >รายละเอียด</label
        >
        <div class="col-sm-9">
          <vue-editor v-model="form.detail" :editor-toolbar="customToolbar" />
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label"
          >ราคาเปิดประมูล</label
        >
        <div class="col-sm-9">
          <div class="form-row">
            <div class="col-8">
              <input
                type="number"
                class="form-control"
                placeholder
                v-model="form.price_open"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="6"
              />
            </div>
            <div class="col" style="padding-top:7px;">
              <span>บาท</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label"
          >ราคาเคาะ</label
        >
        <div class="col-sm-9">
          <div class="form-row">
            <div class="col-8">
              <input
                type="number"
                class="form-control"
                placeholder
                v-model="form.price_step"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                maxlength="4"
              />
            </div>
            <div class="col" style="padding-top:7px;">
              <span>บาท</span>
            </div>
          </div>
          <small id="emailHelp" class="form-text text-muted"
            >เคาะได้ไม่เกิน 5 เท่า ของราคาเปิดประมูล</small
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-3 col-form-label"
          >การปิดประมูล</label
        >
        <div class="col-sm-9">
          <b-tabs content-class="mt-3" @input="onSelectTab" v-model="tabIndex">
            <b-tab title="ปิดโดยราคา" active>
              <div class="form-group row">
                <div class="col-sm-10">
                  <div class="form-row">
                    <div class="col-8">
                      <input
                        type="number"
                        class="form-control"
                        placeholder
                        v-model="form.price_close"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="4"
                      />
                    </div>
                    <div class="col" style="padding-top:7px;">
                      <span>บาท</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="ปิดโดยเวลา">
              <b-form-group
                id="fieldset-horizontal"
                label-cols-sm="4"
                label-cols-lg="5"
                content-cols-sm
                content-cols-lg="12"
                label="วันที่"
                label-for="input-horizontal"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="form.close_time"
                  calendar-width="100%"
                  menu-class="w-100"
                  :hide-header="true"
                  :label-no-date-selected="'กรุณาเลือกวัน'"
                  :label-today-button="'วันนี้'"
                  :today-button="true"
                  :min="dateRang.min"
                  :max="dateRang.max"
                ></b-form-datepicker> </b-form-group
              ><b-form-group
                id="fieldset-horizontal"
                label-cols-sm="4"
                label-cols-lg="5"
                content-cols-sm
                content-cols-lg="12"
                label="เวลา"
                label-for="input-horizontal"
              >
              <b-form-select v-model="form.hour" style="width:100px">
                <option value="" selected disabled>ชั่วโมง</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
              </b-form-select> :
              <b-form-select v-model="form.min" style="width:100px">
                <option value="" selected disabled>นาที</option>
                <option value="00">00</option>
                <option value="30">30</option>
              </b-form-select>
                </b-form-group></b-tab>
          </b-tabs>
        </div>
      </div>

      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label"></label>
        <div class="col-sm-10">
          <button
            type="button"
            class="btn btn-yellow btn-block btn-save"
            @click="posting"
          >
            บันทึก
          </button>
        </div>
      </div>
    </form>
    <SelectCategory
      :show="showSelectCat"
      :options="categorys"
      @onSelected="onSelected"
      @onClose="showSelectCat = false"
    ></SelectCategory>
  </div>
</template>

<script>
import moment from "moment";
import categoryService from "@/services/Category";
// import provinceService from "../services/Province";
import PostService from "@/services/posts";

import Upload from "@/components/UploadFile";
import SelectCategory from "@/components/SelectCategory";

import { VueEditor } from "vue2-editor";

import AutoInput from "@/components/AutoInput";

import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";

// import VueNumeric from "vue-numeric";

export default {
  name: "posting",
  components: {
    Upload,
    SelectCategory,
    VueEditor,
    AutoInput,
    // VueTimepicker,
    // DatePicker,
    // VueNumeric,
  },
  data() {


    var startdate = new Date();
    startdate.setDate(startdate.getDate() + 1);
    var enddate = new Date();
    enddate.setDate(enddate.getDate() + 3);

    var close_time = moment(startdate).format("YYYY-MM-DD") + " 17:00";

    return {
      dateRang: {
        min: startdate,
        max: enddate,
      },
      tabIndex: 1,
      selectTab: 1,
      // c_date: null,
      showSelectCat: false,
      selecCatDisplay: "กรุณาเลือกประเภท",
      categorys: [],
      provinces: [],
      form: {
        post_id: null,
        post_code: null,
        title: "",
        category: null,
        // province: null,
        photos: [],
        detail: "",
        price_open: null,
        price_close: null,
        price_step: null,
        price_step_display: "",
        isInsure: false,
        insureDate: 30,
        date: startdate,
        time: "17:00",
        close_time: close_time,
        close_type: "price",
        hour: "17",
        min: "00"

        // isReturnComplete: false
      },
      post: {},
      images: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      lastPost: [],
      close: null,
    };
  },
  async created() {
    this.getCategory();

    let code = this.$route.params.code;

    if (code) await this.getPosting(code);

    // this.getLastPost();

    // console.log(code);
    // this.getProvince();
  },
  methods: {
    onContext(ctx) {
        // The date formatted in the locale, or the `label-no-date-selected` string
        this.formatted = ctx.selectedFormatted
        // The following will be an empty string until a valid date is entered
        this.selected = ctx.selectedYMD
        console.log(this.formatted)
        console.log(this.selected)
      },
    dateDisabled(ymd, date) {
      // console.log(ymd);
      console.log(date);
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();

      var today = new Date();
      var tomorrow = new Date();

      //add a day to the date
      tomorrow.setDate(tomorrow.getDate() + 3);
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 13;
      return date < today || date > tomorrow;
    },
    getLastPost() {
      PostService.getlast().then((res) => {
        // console.log(res)
        // this.categorys = res.data;
        this.lastPost = res.data;
      });
    },
    // getProvince() {
    //   provinceService.list().then(res => {
    //     this.provinces = res.data;
    //   });
    // },
    calPriceStep() {
      if (this.form.price_open && this.form.price_close) {
        let level = this.form.price_close / this.form.price_open;
        this.form.price_step = level;

        if (level <= 10) this.form.price_step_display = this.s10;
        else if (level <= 20) this.form.price_step_display = this.s20;
        else if (level <= 50) this.form.price_step_display = this.s50;
        else if (level <= 100) this.form.price_step_display = this.s100;
        else this.form.price_step_display = this.s300;
      }
    },
    getCategory() {
      categoryService.list().then((res) => {
        this.categorys = res.data;
      });
    },
    posting() {
      console.log(this.form);

      let _self = this;

      if (this.form.title == null || this.form.title == "") {
        this.$swal.fire(
          "ชื่อประกาศ",
          "โปรดใส่ชื่อประการที่จะทำการประมูล",
          "warning"
        );
        return;
      }

      if (this.form.category == null) {
        this.$swal.fire(
          "เลือกประเภท",
          "กรุณาเลือกประเภทให้ตรงกับพระที่คุณประมูล",
          "warning"
        );
        return;
      }

      if (this.form.photos.length == 0) {
        this.$swal.fire(
          "เลือกรูปภาพ",
          "กรุณาเลือกรูปพระในการโพส อย่างน้อย 1 รูป",
          "warning"
        );
        return;
      }

      if (this.form.price_open <= 0 || this.form.price_open == "") {
        this.$swal.fire(
          "กรอกราคา เปิดประมูล",
          "ราคากรอกได้ตั้งแต่ 1 บาทขึ้นไป",
          "warning"
        );
        return;
      }

      if (this.form.price_step <= 0 || this.form.price_step == "") {
        this.$swal.fire(
          "กรอกราคาเคาะ",
          "กรุณากรอกราคาเคาะให้ถูกต้อง",
          "warning"
        );
        return;
      }

      if (parseInt(this.form.price_step) > parseInt(this.form.price_open) * 5) {
        this.$swal.fire(
          "ราคาเคาะไม่ถูกต้อง",
          "ราคาเคาะต้องไม่เกิน 5 เท่าของราคาเปิดประมูล",
          "warning"
        );
        return;
      }


      if(this.form.close_type == 'price'){
        if (this.form.price_close == null || this.form.price_close <= 0) {
          this.$swal.fire(
            "กรอกราคา ปิดประมูล",
            "กรุณากรอกราคาปิดประมูล",
            "warning"
          );
          return;
        }
        if (
          parseInt(this.form.price_close) <=
          parseInt(this.form.price_open) + parseInt(this.form.price_step)
        ) {
          this.$swal.fire(
            "ราคาปิดไม่ถูกต้อง",
            "ราคา ปิดประมูล ต้องมากกว่าหรือเท่ากับราคาเปิดประมูล+ราคาเคาะ ",
            "warning"
          );
          return;
        }
      }
      
      

      // if (this.form.c_date != null && this.form.c_time != null) {
      //   let date = this.form.c_date + " " + this.form.c_time;

      //   this.form.close_time = date;
      // } else {
      //   this.form.close_time = null;
      // }

      // this.form.close_time = this.form.hour + ":" + this.form.time

      // console.log(this.form);
      this.form.close_time = moment(this.form.close_time).format('YYYY-MM-DD')+ " "+ this.form.hour +":"+this.form.min;

      // if (this.form.price_close <= 0 || this.form.price_close == "") {
      //   this.$swal.fire(
      //     "กรอกราคา ปิดประมูล",
      //     "กรุณากรอกราคาปิดประมูล",
      //     "warning"
      //   );
      //   return;
      // }
      // if (
      //   parseInt(this.form.price_close) <=
      //   parseInt(this.form.price_open) + parseInt(this.form.price_step)
      // ) {
      //   this.$swal.fire(
      //     "ราคาปิดไม่ถูกต้อง",
      //     "ราคา ปิดประมูล ต้องมากกว่าหรือเท่ากับราคาเปิดประมูล+ราคาเคาะ ",
      //     "warning"
      //   );

      //   return;
      // }

      // this.price_step = Math.ceil(this.price_step/100)*100;

      if (this.form.post_code) {
        this.$swal
          .fire({
            title: "ปรับปรุงขอมูล?",
            text: _self.form.title,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "gold",
            cancelButtonColor: "gray",
            confirmButtonText: "ใช่ยืนยัน",
            cancelButtonText: "ยกเลิก",
          })
          .then((result) => {
            if (result.value) {
              PostService.update(_self.form)
                .then(() => {
                  this.$swal
                    .fire(
                      "บันทึกข้อมูลเรียบร้อยแล้ว!",
                      "กรุณารอการอนุมัติโพส",
                      "success"
                    )
                    .then(() => {
                      _self.$router.push("/member/posts");
                    });
                })
                .catch((err) => {
                  this.$swal({
                    position: "top",
                    icon: "error",
                    title: "แก้ไขไม่สำเร็จ",
                    text: err.data.error,
                    showConfirmButton: true,
                    timer: 1500,
                  });
                });
              //Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
          });
      } else {
        this.$swal
          .fire({
            title: "ลงประมูล?",
            text: "กรุณายืนยันการบันทึกข้อมูล และรอการอนุมัติจากเรา",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "gold",
            cancelButtonColor: "gray",
            confirmButtonText: "ใช่ยืนยัน",
            cancelButtonText: "ยกเลิก",
          })
          .then((result) => {
            if (result.value) {
              PostService.create(_self.form)
                .then(() => {
                  this.$swal
                    .fire(
                      "บันทึกข้อมูลเรียบร้อยแล้ว!",
                      "กรุณารอการอนุมัติโพส",
                      "success"
                    )
                    .then(() => {
                      _self.$router.push("/member/posts");
                    });
                })
                .catch((err) => {});
              //Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
          });
      }
    },
    onSelectFile(file) {
      // console.log(file);
      this.form.photos = file;
    },
    onSelected(cat) {
      this.form.category = cat.id;
      this.selecCatDisplay = cat.text;
      this.showSelectCat = false;
    },
    getPosting(code) {
      PostService.getDetail(code)
        .then((res) => {
          //console.log("res", res);
          let post = res.data.post;
          this.post = post;

          let category = res.data.category;
          let parent = res.data.parent;
          let date_time = null;

          this.images = res.data.images;
          this.form.post_id = post.id;
          this.form.post_code = post.post_code;
          this.form.title = post.title;
          this.form.detail = post.detail;
          this.form.price_open = post.price_open;
          this.form.price_close = post.price_close;
          this.form.price_step = post.price_step;

          this.form.isInsure = post.isInsure == 1 ? true : false;
          this.form.insureDate = post.insureDate;
          if (post.close_time != null) {
            date_time = moment(post.close_time);
            // let date_time = moment(post.close_time);
            // this.form.c_date = date_time.format("YYYY-MM-DD");
            // this.form.c_time = date_time.format("HH:mm");
            this.form.close_time = date_time.format("YYYY-MM-DD");
            this.form.hour = date_time.format("HH");
            this.form.min = date_time.format('mm')
          }

          if(post.close_type != null){
            if(post.close_type == 'price') this.tabIndex = 0;
            else this.tabIndex = 1;
          }

          // this.form.close_time = post.close_time;
          // this.form.isReturnComplete =
          //   post.isReturnComplete == 1 ? true : false;
          this.form.category = category.cat_id;
          this.selecCatDisplay = parent.cat_name + ">>" + category.cat_name;
        })
        .catch({});
    },
    onSelectTab(tab) {

      if (tab == 0) {
        this.form.close_type = 'price'
      } else {
        this.form.close_type = 'time'
        
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  color: gold;
}
.form-post {
  background-color:white;
  margin-bottom:10px;
  border-radius: 10px;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto 0 auto;
  h2 {
    margin: 20px 0 30px 0;
  }

  // .col-form-label{
  //   text-align:right;
  // }
  
}

.upload-box {
  width: 100%;
  height: 200px;
  border: 1px dashed #cacaca;
  border-radius: 5px;
  //
  padding: 10px;
  // background-color: #e0ffd1;
}

.btn-upload {
  cursor: pointer;
  height: 100px;
  width: 100px;
  border: 1px solid #c3dbc3;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

input,
textarea,
select {
  // background-color: #e8fddd;
  // border: none;
}

*:focus {
  outline: none;
}

.close-date {
  width: 100%;
}
input[type="number"] {
  text-align: right;
}
.btn-save {
  background-color: gold;
}
.body-content{
  background-color:white;
}
</style>
