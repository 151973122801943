import HTTP from "./http-common";

export default {
  list() {
    return HTTP.get("category")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  info(cat_id) {
    return HTTP.get("category/" + cat_id)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
};
