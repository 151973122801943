import Vue from "vue";

export default {
  addComma(value) {
    if (!value || value == 0) return "-";
    value = Number(value).toLocaleString();
    return value;
  }
};

// Vue.filter("addComma", function(value) {
//   if (!value || value == 0) return "-";
//   value = Number(value).toLocaleString();
//   return value + " บาท";
// });
