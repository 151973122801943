<template>
  <div id="select-category">
    <div class="modal" v-if="show">
      <div class="modal-content">
        <span class="back" @click.stop="onBack" v-if="index > 0">
          <i class="fas fa-caret-left" style="font-size:40px;"></i>
        </span>

        <i
          class="fas fa-times close"
          style="font-size:24px;"
          @click.stop="onClose"
        ></i>
        <div class="header">
          <h3>เลือกหมวดหมู่</h3>
        </div>
        <ul class="item-list scroll scroll4">
          <li
            v-for="(item, idx) in data"
            @click.stop="onSelect(item)"
            :key="idx"
          >
            {{ item.cat_name }}
            <span
              class="more-item"
              v-if="item.children && item.children.length > 0"
            >
              <i class="fas fa-caret-right" style="font-size:20px;"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dialog-select-category",
  props: ["show", "options"],
  data() {
    return {
      dataBack: [],
      index: 0,
      parent: 0,
      data: [],
      textDisplay: "",
    };
  },
  watch: {
    options(value) {
      this.data = value;
    },
  },
  created() {
    // this.parent = 0;
  },
  methods: {
    onSelect(item) {
      // this.textDisplay = "";
      if (item.children) {
        //console.log("children");

        this.dataBack[this.index] = this.data;
        this.data = item.children;
        this.index++;
        this.textDisplay += item.cat_name + " > ";
      } else {
        //("last");

        this.textDisplay += item.cat_name;
        this.$emit("onSelected", { id: item.cat_id, text: this.textDisplay });
        this.textDisplay = "";
        this.index = 0;
        this.data = this.options;

        // console.log(this.textDisplay);

        // this.$emit("onClose", false);
      }
    },
    onBack() {
      this.textDisplay = "";
      if (this.index > 0) {
        this.index--;
        this.data = this.dataBack[this.index];
      }
    },
    onClose() {
      this.textDisplay = "";
      this.parent = 0;
      this.$emit("onClose", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-category {
  // z-index: 100;
}
.header {
  padding: 10px;
  text-align: center;
}
.search {
  padding: 10px;
}
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300; /* Sit on top */
  padding-top: 80px; /* Location of the box */
  left: 0;
  top: 0;
  // width: 100%; /* Full width */
  // height: 100%; /* Full height */
  // overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  // padding: 20px;
  border: 1px solid #888;
  width: 400px;
  height: 600px;
}

/* The Close Button */
.close {
  position: absolute;
  right: 10px;
  top: 10px;

  color: #aaaaaa;
  // float: right;
  font-size: 48px;
  // font-weight: bold;
}

.back {
  position: absolute;
  // left: 20px;
  // top: 20px;
  cursor: pointer;
  padding: 20px;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.item-list {
  margin-top: 10px;
  // height: 600px;
  overflow: auto;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 10px;
  overflow: auto;
}

li:hover {
  background: #eee;
  cursor: pointer;
}

.scroll4::-webkit-scrollbar {
  width: 10px;
}

.scroll4::-webkit-scrollbar-thumb {
  background: rgb(168, 168, 168);
  border-radius: 20px;
}

.scroll4::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}
.more-item {
  float: right;
}

@media only screen and (max-width: 600px) {
  .modal {
    height: 100% !important;
    padding-top: 0px !important;
    .modal-content {
      width: 100% !important;
      height: 100% !important;
      margin: 0px !important;
    }
  }
}
</style>
