<template>
  <div class="main">
    <div
      class="img-title"
      style="padding-top:150px;line-height:30px;"
      ref="imgTitle"
    >
      <div class="container">
        <div class="title">เรามีพระในระดับรากหญ้า</div>
        <div class="title small">ในราคาที่คุณพอใจ</div>
      </div>
    </div>

    <div class="container">
      <div class="main-content">
        <Category></Category>

        <h4 class="title-header">ยอดประมูลสูงสุด</h4>
        <PostTop></PostTop>

        <h4 class="title-header">ประกาศใหม่</h4>
        <PostNew></PostNew>
      </div>
    </div>

    <div class="container" style="margin-top:50px;">
      <h4 class="title-header">พระคุณ.com เป็นเว็บตัวกลางสำหรับประมูลพระ</h4>
      <p>
        เป็นตลาดซื้อขายแลกเปลี่ยนพระเพื่อให้ทุกคนได้ใช้งานสะดวกและ
        ง่ายต่อการประมูลพระ
      </p>
    </div>
  </div>
</template>

<script>
// import CustomSelect from "@/components/CustomSelect.vue";
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import favoriteService from "@/services/favorite";
import categoryService from "../services/Category";

import Category from "../components/Category";

import PostNew from "../components/PostNew.vue";
import PostTop from "../components/PostTop.vue";

export default {
  name: "Home",
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  components: {
    Category,
    PostNew,
    PostTop,
  },
  data() {
    return {
      province_th: [],
      favorites: [],
      categorys: [],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);

    if (this.$store.getters["Account/isLoggedIn"] == true) {
      // this.getFavorite();
    }
    //
    // this.getCategory();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      var pos = window.scrollY;
      let velocity = 0.5;

      let height = this.$refs.imgTitle.clientHeight - 90;

      this.$refs.imgTitle.style.backgroundPosition =
        "0 " + Math.round((height - pos) * velocity) + "px";
    },

    goToSearch() {
      // console.log("goto");
      this.$router.push("/search");
    },

    // getTopPost() {
    //   postService.topPost().then((res) => {
    //     this.topPost = res.data;
    //   });
    // },
    getFavorite() {
      favoriteService
        .list()
        .then((res) => {
          this.favorites = [];
          res.data.forEach((item) => {
            this.favorites.push(item.post_id);
          });
        })
        .catch({});
    },
    onLike(item) {
      // console.log(item);
      favoriteService
        .add(item.id)
        .then(() => {
          // alert("save แล้ว");
          this.$swal.fire({
            position: "top-end",
            // title: "Auto close alert!",
            text: "บันทึกข้อมูลแล้ว",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          this.getFavorite();
        })
        .catch({});
    },

    gotoArticle(id) {
      this.$router.push({
        path: "/article/" + id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-title {
  // box-shadow: inset 0px 0px 102px 130px rgba(255, 255, 255, 1);
  // position: relative;
  background-position-y: 80px;
  // background-repeat: no-repeat;
  background-attachment: fixed;
  // background-position: center;
  background-size: 100% 250px;

  background-image: url("~@/assets/images/new-background.jpg");
  height: 250px;

  line-height: 20px;
  // .contains {
  //   width: 80%;

  .title {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: gray;
    font-size: 36px;
    color: #ffffff;

    // text-shadow: 1px 1px #a1a1a1;
  }
}

.title-header {
  color: gold;
  margin-bottom: 20px;
}

.my-card {
  width: 100%;
  min-height: 250px;
  padding-bottom: 5px;
  // border: 1px solid #d4d4d4;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.my-card:hover {
  box-shadow: 0 8px 16px 0 #00000033;
}
/// card

.seller {
  padding: 10px;
}

.seller-image {
  vertical-align: top;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.seller-detail {
  margin-left: 10px;
  display: inline-block;
  .name {
    font-size: 20px;
    color: goldenrod;
  }
  .detail {
    font-size: 14px;
    color: gray;
  }
}
.seller-products {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin: 5px;

  .product {
    flex: 0 0 auto;
    margin-right: 10px;
    // border: 1px solid gray;
    height: 250px;
    width: 150px;
    cursor: pointer;

    .title {
      color: #292929;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .province {
      color: gray;
    }

    .parent {
      display: flex;
      align-items: center;
      .price {
        font-size: 18px;
        color: green;
        flex-grow: 1;
        font-size: 12px;
      }
      .last-price {
        font-size: 16px;
        color: goldenrod;
      }
    }
  }
}

.description {
  color: green;
}

// @media screen and (max-width: 900px) {
//   .flex_item_left {
//     display: none;
//   }
//   .grid-container {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (max-width: 1000px) {
//   .grid-container {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

.img-category {
  border-radius: 5px;
  margin-right: 5px;
}

.article {
  cursor: pointer;
}

.empty-post {
  // height: 20px;
  margin-bottom: 20px;
  margin-left: 20;
}
</style>
