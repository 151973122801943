<template>
  <div name="modal" v-show="show">
    <div class="modal-mask" @click="close()">
      <div class="modal-wrapper">
        <div class="modal-container" :style="style" @click.stop>
          <div class="close" @click="close()"></div>

          <div class="modal-header">
            <slot name="header">header</slot>
          </div>

          <div class="modal-body">
            <slot name="body">body</slot>
          </div>

          <!-- <div class="modal-footer">
            <slot name="footer">
              footer
            </slot>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: { show: Boolean, height: String, width: String },
  data() {
    return {};
  },
  computed: {
    style() {
      return {
        height: this.height ? this.height : "300px",
        width: this.width ? this.width : "300px",
      };
    },
  },
  methods: {
    close() {
      //   console.log("close modal");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 1s ease;
  // opacity: 0;
  // transition: opacity 0.3s 0.1s, ease;
  // transition: opacity 0.3s 0.1s, height 0.3s;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  // overflow-y: scroll;
  width: 400px;
  height: 550px !important;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.1s ease;
  //   font-family: Helvetica, Arial, sans-serif;
  // overflow: scroll;
  // -webkit-overflow-scrolling: touch;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 10px;
  width: 24px;
  height: 24px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 24px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.modal-header {
  margin-top: 0;
  font-size: 32px;
  color: #05b805;
  //   border: none !important;
}

.modal-body {
  margin: 10px 0;
  // overflow: scroll;
  // -webkit-overflow-scrolling: touch;
}

// .modal-default-button {
//   float: right;
// }

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
  .modal-container {
    width: 100% !important;
    height: 100% !important;
  }
}

// @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
//   .modal-container {
//     width: 100% !important;
//     height: 100% !important;
//   }
// }

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
//   .modal-container {
//     width: 100% !important;
//     height: 100% !important;
//   }
// }
</style>
