import Vue from "vue";
import store from "../store";
import axios from "axios";

import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import Detail from "../views/Detail";
import Profile from "../views/Profile";
import Post from "../views/Post";
import Posting from "../views/Posting";
import Cart from "../views/Cart";

import About from "../views/About";
import Help from "../views/Help";

import Layout from "../views/Layout";
// import LayoutMember from "../views/Member/Layout";
import MemberLayout from "../views/Member/Layout";
import Activate from "../components/Activate";

import MemberPosting from "../views/Member/Posting";
import MemberPosts from "../views/Member/Posts";
import MemberWaitApprove from "../views/Member/WaitApprove";
import MemberBidClose from "../views/Member/BidClose";
import MemberHistory from "../views/Member/History";
import MemberFavorite from "../views/Member/Favorite";
// import MemberMyClose from "../views/Member/MyClose";
// import MemberCloseMy from "../views/Member/CloseMy";
import MemberNotification from "../views/Member/Notification.vue";

import MemberPostClose from "../views/Member/PostClose.vue";
import MemberPostWin from "../views/Member/PostWin.vue";

import MemberInfo from "../views/Member/Info";
import BuySale from "../views/Member/BuySale";

import MemberEditPosting from "../views/Member/Posting";

import MemberService from "../services/Member";

import Policy from "../views/Policy";
import Notfound from "../views/Notfound";

import Groups from "../views/Groups";

import AccountService from "@/services/Accounts";

import ArticleDetail from "../views/ArticleDetail";

import AuthLineLogin from "../views/AuthLineLogin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/line",
    name: "auth-line",
    component: AuthLineLogin,
  },
  {
    path: "/auth/gooogle",
    name: "auth-google",
    component: AuthLineLogin,
  },
  {
    path: "/",
    component: Layout,
    async beforeEnter(to, from, next) {
      // console.log(to.query.code);
      // console.log(from);
      let token = localStorage.getItem("token");
      if (token) {
        // next();
        await MemberService.me()
          .then((res) => {
            store.dispatch("Account/setDetail", res.data).then(() => {
              next();
            });
            // next();
          })
          .catch(() => next());
      } else {
        next();
      }
    },
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
      },
      {
        path: "/new-post",
        name: "NewPost",
        component: Post,
      },
      {
        path: "/search",
        name: "Search",
        component: Search,
      },
      {
        path: "article/:id",
        name: "article",
        component: ArticleDetail,
      },
      {
        path: "/detail",
        name: "Detail",
        component: Detail,
      },
      {
        path: "/post/:code",
        name: "post detail",
        component: Detail,
        // beforeRouteLeave(to, from) {
        //   const answer = window.confirm(
        //     "Do you really want to leave? you have unsaved changes!"
        //   );
        //   if (!answer) return false;
        // },
      },
      {
        path: "/activate",
        name: "activate",
        component: Activate,
      },
      {
        path: "/category/:id/:name",
        name: "group-name-id",
        component: Groups,
      },
      {
        path: "/category/:id",
        name: "group",
        component: Groups,
      },
      {
        path: "/member/:id/view",
        name: "member-info",
        component: MemberInfo,
      },
      {
        path: "/member",
        component: MemberLayout,
        beforeEnter: (to, from, next) => {
          // console.log(to);
          // if (localStorage.getItem("token")) {
          //   next();
          // } else {
          // alert("must login");
          // store.getters['Account/isLoggedIn', res.data).then(() => {
          //   next();
          // });
          if (store.getters["Account/isLoggedIn"] == true) {
            next();
          } else {
            store.dispatch("Account/showLogin", true);
            // next("/");
            if (!from.name) next("/");
          }

          // next("/login");

          // }
        },
        children: [
          {
            path: "/",
            name: "member",
            component: Profile,
            // meta: { requiresAuth: true }
          },
          {
            path: "posting",
            name: "member-posting",
            component: MemberPosting,
          },
          {
            path: "posts",
            name: "member-post",
            component: MemberPosts,
            // meta: { requiresAuth: true }
          },
          {
            path: "posts/:code/edit",
            name: "member-edit-post",
            component: MemberEditPosting,
            // meta: { requiresAuth: true }
          },
          {
            path: "profile",
            name: "Profile",
            component: Profile,
          },
          {
            path: "wait-approve",
            name: "member-wait-approve",
            component: MemberWaitApprove,
            // meta: { requiresAuth: true }
          },
          {
            path: "bid-close",
            name: "member-bid-close",
            component: MemberBidClose,
            // meta: { requiresAuth: true }
          },
          {
            path: "favorite",
            name: "member-favorite",
            component: MemberFavorite,
            // meta: { requiresAuth: true }
          },
          {
            path: "history",
            name: "member-history",
            component: MemberHistory,
            // meta: { requiresAuth: true }
          },
          // {
          //   path: "my-close",
          //   name: "my-close",
          //   component: MemberMyClose,
          //   // meta: { requiresAuth: true }
          // },
          // {
          //   path: "close-my",
          //   name: "close-my",
          //   component: MemberCloseMy,
          //   // meta: { requiresAuth: true }
          // },
          {
            path: "notification",
            name: "notification",
            component: MemberNotification,
            // meta: { requiresAuth: true }
          },
          {
            path: "post-close",
            name: "post-close",
            component: MemberPostClose,
          },
          {
            path: "post-win",
            name: "post-win",
            component: MemberPostWin,
          },
          {
            path: "buy-sale",
            name: "buy-sale",
            component: BuySale,
          },
        ],
      },

      {
        path: "help",
        name: "help",
        component: Help,
      },
      {
        path: "about",
        name: "about",
        component: About,
      },
      {
        path: "policy",
        name: "policy",
        component: Policy,
      },
      {
        path: "*",
        name: "notfound",
        component: Notfound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
