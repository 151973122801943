<template>
  <div class="form-post">
    <h2>ลงประกาศขายใหม่</h2>
    <form>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label"
          >ชื่อประกาศ</label
        >
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            placeholder
            v-model="form.title"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label">ประเภท</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            placeholder="กรุณาเลือกประเภท"
            @click="showSelectCat = true"
            v-model="selecCatDisplay"
            readonly
            style="cursor:pointer"
          />
        </div>
      </div>
      <!-- <div class="form-group row">
          <label for="inputEmail3" class="col-sm-2 col-form-label">จังหวัด</label>
          <div class="col-sm-10">
            <select class="custom-select" v-model="form.province">
              <option value>-ทุกประเภท-</option>
              <option
                v-for="(item, idx) in provinces"
                :key="idx"
                :value="item.id"
              >{{ item.name_th }}</option>
            </select>
          </div>
      </div>-->
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label">รูปภาพ</label>
        <div class="col-sm-10">
          <Upload @onUpdate="onSelectFile"></Upload>
          <!-- <div class="upload-box">
              <div class="btn-upload">เลือกรูป</div>
          </div>-->
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword3" class="col-sm-2 col-form-label"
          >รายละเอียด</label
        >
        <div class="col-sm-10">
          <textarea
            class="form-control"
            rows="5"
            v-model="form.detail"
          ></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label"
          >ราคาเปิดประมูล</label
        >
        <div class="col-sm-5">
          <input
            type="number"
            class="form-control"
            placeholder
            v-model="form.price_open"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label"
          >ราคาปิดประมูล</label
        >
        <div class="col-sm-5">
          <input
            type="number"
            class="form-control"
            placeholder
            v-model="form.price_close"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label"
          >ราคาเคาะ</label
        >
        <div class="col-sm-5">
          <input
            type="number"
            class="form-control"
            placeholder
            v-model="form.price_step"
          />
          <small id="emailHelp" class="form-text text-muted"
            >เคาะได้ไม่เกิน 5 เท่า ของราคาที่ตั้งไว้</small
          >
        </div>
      </div>
      <div class="form-group row">
        <label for="inputEmail3" class="col-sm-2 col-form-label"></label>
        <div class="col-sm-10">
          <button
            type="button"
            class="btn btn-yellow btn-block"
            @click="posting"
          >
            ลงขาย
          </button>
        </div>
      </div>
    </form>
    <SelectCategory
      :show="showSelectCat"
      :options="categorys"
      @onSelected="onSelected"
    ></SelectCategory>
  </div>
</template>

<script>
import categoryService from "../services/Category";
// import provinceService from "../services/Province";
import PostService from "../services/posts";

import Upload from "../components/UploadFile";
import SelectCategory from "../components/SelectCategory";

export default {
  name: "posting",
  components: {
    Upload,
    SelectCategory,
  },
  data() {
    return {
      showSelectCat: false,
      selecCatDisplay: "กรุณาเลือกประเภท",
      categorys: [],
      provinces: [],
      form: {
        title: "",
        category: null,
        // province: null,
        photos: [],
        detail: "",
        price_open: 0,
        price_close: 0,
        price_step: 0,
      },
    };
  },
  created() {
    this.getCategory();
    // this.getProvince();
  },
  methods: {
    // getProvince() {
    //   provinceService.list().then(res => {
    //     this.provinces = res.data;
    //   });
    // },
    getCategory() {
      categoryService.list().then((res) => {
        this.categorys = res.data;
      });
    },
    posting() {
      let _self = this;
      this.$swal
        .fire({
          title: "ลงขาย?",
          text: "กรุณายืนยันการบันทึกข้อมูล และรอการอนุมัติจากเรา",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "gold",
          cancelButtonColor: "gray",
          confirmButtonText: "ใช่ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.value) {
            PostService.create(_self.form)
              .then(() => {
                this.$swal
                  .fire(
                    "บันทึกข้อมูลเรียบร้อยแล้ว!",
                    "กรุณารอการอนุมัติโพส",
                    "success"
                  )
                  .then(() => {
                    _self.$router.push("/member");
                  });
              })
              .catch(() => {});
          }
        });
    },
    onSelectFile(file) {
      //console.log(file);
      this.form.photos = file;
    },
    onSelected(cat) {
      //console.log(cat);
      this.form.category = cat.id;
      this.selecCatDisplay = cat.text;
      this.showSelectCat = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-post {
  padding: 10px;
  max-width: 800px;
  margin: 0 auto 0 auto;
  h2 {
    margin: 20px 0 30px 0;
  }
}

.upload-box {
  width: 100%;
  height: 200px;
  border: 1px dashed #cacaca;
  border-radius: 5px;
  //
  padding: 10px;
  // background-color: #e0ffd1;
}

.btn-upload {
  cursor: pointer;
  height: 100px;
  width: 100px;
  border: 1px solid #c3dbc3;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

input,
textarea,
select {
  // background-color: #e8fddd;
  // border: none;
}

*:focus {
  outline: none;
}
</style>
