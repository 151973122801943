<template>
  <div id="footer">
    <div class="container">
      <div class="row">
        <div class="col d-none d-sm-block">
          <div class="title">ช่วยเหลือ</div>
          <ul class="link">
            <li>
              <router-link to>คำถามที่พบบ่อย</router-link>
            </li>
            <li>
              <router-link to>ขั้นตอนการประมูล</router-link>
            </li>
            <li>
              <router-link to>ร่วมงาน</router-link>
            </li>
          </ul>
        </div>
        <!-- <div class="col d-none d-sm-block">
          <div class="title">ประเภทพระ</div>
          <ul>
            <li>
              <router-link to>กระกริ่ง</router-link>
            </li>
            <li>
              <router-link to>พระนักกล้าม</router-link>
            </li>
            <li>
              <router-link to>พระสมเด็จ</router-link>
            </li>
            <li>
              <router-link to>ทรงสี่เหลี่ยม</router-link>
            </li>
          </ul>
        </div> -->
        <div class="col d-none d-sm-block">
          <div class="title">เกี่ยวกับเรา</div>
          <ul class="link">
            <li>
              <router-link to>ข้อกำหนดและการใช้งาน</router-link>
            </li>
            <li>
              <router-link to>นโยบายความเป็นส่วนตัว</router-link>
            </li>
            <li>
              <router-link to>เกี่ยวกับ พระคุณ.com</router-link>
            </li>
            <li>
              <router-link to>ติดต่อโฆษณา</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="row justify-content-center">
        <!-- hitwebcounter Code START -->
        <a href="https://www.hitwebcounter.com" target="_blank">
          <img
            src="https://hitwebcounter.com/counter/counter.php?page=7719728&style=0006&nbdigits=6&type=ip&initCount=15620"
            title="Total Website Hits"
            Alt="Web Hits"
            border="0"
        /></a>
      </div>
      <div class="row">
        <div class="contain-footer">
          <div class="col copy-right">สงวนลิขสิทธิ์ © 2563 พระคุณ.com</div>
          <!-- <img class="img-download" src="@/assets/google-download.webp" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
#footer {
  // background-color: #e1f3d8;
  padding: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 191, 0, 1) 0%,
    rgba(255, 229, 150, 1) 3%,
    rgba(255, 255, 255, 1) 77%,
    rgba(255, 255, 255, 1) 100%
  );
}
.title {
  color: goldenrod;
  margin-bottom: 15px;
  font-size: 20px;
}
.contain-footer {
  text-align: center;
  padding-top: 20px;
  width: 100%;
  .copy-right {
    font-size: 12px;
    color: gray;
  }
  .img-download {
    margin-top: 10px;
    height: 40px;
  }

  .link {
    color: red !important;
  }
}
</style>
