<template>
  <div>
    <div class="container">
      <h4 class="title-header">ประกาศใหม่</h4>
      <div class="grid-container" v-if="favorites.length > 0">
        <div
          class="card-product"
          v-for="(item, nidx) in favorites"
          :key="'new' + nidx"
          @click="goToDetail(item.post_code)"
        >
          <div class="img-post">
            <img
              class="img-product"
              v-if="item.title_img && !null"
              :src="base_img + item.title_img"
              alt="รูป"
            />
            <img
              v-else
              src="@/assets/images/noimage.png"
              alt="ไม่พบรูปภาพ"
              width="100%"
            />
            <div class="close-label" v-if="item.status == 'close'">
              ปิดประมูล
            </div>

            <img
              src="@/assets/images/remove.png"
              width="16px"
              height="16px"
              @click="remove"
              class="remove"
            />
          </div>
          <div class="detail">
            <div class="title">{{ item.title }}</div>
            <div class="province">{{ item.province_name }}</div>
            <div class="price">
              <div class="open">เปิด {{ item.price_open | addComma }}</div>
              <div class="last">
                ราคาปัจจุบัน {{ item.last_price | addComma }}
              </div>
            </div>
            <!-- <div class="parent"></div> -->
          </div>
        </div>
        <!-- <div class="card-product" v-for="index in 10" :key="index" @click="goToDetail()">
          <img src="@/assets/images/pra001.jpg" alt="Avatar" style="width:100%" />
          <div class="detail">
            <div class="title">พระ</div>
            <div class="province">ชลบุรี</div>
            <div class="parent">
              <div class="price">เปิด 13,500,000</div>
            </div>
          </div>
        </div>-->
      </div>
      <div v-else>
        ยังไม่มีประกาศ
      </div>
    </div>
  </div>
</template>

<script>
import favoriteService from "@/services/favorite";
export default {
  name: "favorite",
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  data() {
    return {
      favorites: [],
    };
  },
  created() {
    this.getFavorite();
  },
  methods: {
    getFavorite() {
      favoriteService
        .all()
        .then((res) => {
          this.favorites = res.data;
        })
        .catch({});
    },
    remove() {},
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;

  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.5rem;
  padding: 10px;
}

// @media screen and (max-width: 900px) {
//   .flex_item_left {
//     display: none;
//   }
//   .grid-container {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// @media screen and (max-width: 1000px) {
//   .grid-container {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .contains {
    margin-top: 30px !important;
  }
  .card-product {
    // height: 150px;
    .img-product {
      width: 120px !important;
      height: 100px !important;
      float: left;
      border-radius: 5px !important;
      margin: 5px !important;
    }
    .detail {
      padding-left: 135px !important;
    }

    .close-label {
      left: 50px;
    }

    .remove {
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: 20;
      height: 10px !important;
      width: 16px !important;
    }
  }
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 5px;
  }
  .my-card {
    margin-bottom: 10px;
  }
  .custom-select {
    margin-bottom: 10px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .custom-select {
    margin-bottom: 10px;
  }
  .img-post {
    height: 150px;
    overflow: hidden;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .img-post {
    height: 180px;
    overflow: hidden;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .img-post {
    height: 180px;
    overflow: hidden;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }
  .img-post {
    height: 180px;
    overflow: hidden;
  }
}

.price {
  font-size: 12px;
  .open {
    color: #008b13;
  }
  .last {
    font-size: 16px;
    color: #ca0000;
  }
}

.img-post {
  position: relative;
  // height: 180px;
  // overflow:hidden;
  background-color: #f1f1f1;
  text-align: center;

  .close-label {
    color: white;
    font-size: 12px;
    padding: 0 5px 0 5px;
    background-color: #e40202;
    position: absolute;
    top: 8px;
    right: 10px;
    border-radius: 5px;
    width: 70px;
    text-align: center;
  }
  .favorite {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: #ffffff80;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
  }
  .active,
  .favorite:hover {
    background: gold;
  }
}
.card-product {
  position: relative;
}
</style>
