<template>
  <div>
    <ul class="cat">
      <li @click="onSearch('กรุงเทพและปริมณฑล', '1')">
        <img
          src="../assets/category/กทม.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">กรุงเทพและปริมณฑล</div>
      </li>
      <li @click="onSearch('ภาคกลาง', '2')">
        <img
          src="../assets/category/ภาคกลาง.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ภาคกลาง</div>
      </li>
      <li @click="onSearch('ตะวันออก', '3')">
        <img
          src="../assets/category/ตะวันออก.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ภาคตะวันออก</div>
      </li>
      <li @click="onSearch('ภาคอีสานตอนบน', '4')">
        <img
          src="../assets/category/ภาคอีสานตอนบน.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ภาคอีสานตอนบน</div>
      </li>
      <li @click="onSearch('ภาคอีสานตอนล่าง', '5')">
        <img
          src="../assets/category/ภาคอีสานตอนล่าง.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ภาคอีสานตอนล่าง</div>
      </li>
      <li @click="onSearch('ภาคเหนือ', '6')">
        <img
          src="../assets/category/ภาคเหนือ.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ภาคเหนือ</div>
      </li>
      <li @click="onSearch('ภาคตะวันตก', '7')">
        <img
          src="../assets/category/ตะวันตก.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ภาคตะวันตก</div>
      </li>
      <li @click="onSearch('ภาคใต้', '8')">
        <img
          src="../assets/category/ภาคใต้.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ภาคใต้</div>
      </li>
      <li @click="onSearch('พระกรุ', '9')">
        <img
          src="../assets/category/กรุ.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">พระกรุ</div>
      </li>
      <li @click="onSearch('พระเนื้อผง', '10')">
        <img src="../assets/category/ผง.png" height="60" class="img-category" />
        <div class="cat-name">พระเนื้อผง</div>
      </li>
      <li @click="onSearch('พระกริ่ง พระชัยวัฒน์', '11')">
        <img
          src="../assets/category/พระกริ่ง พระชัยวัฒน์.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">พระกริ่ง พระชัยวัฒน์</div>
      </li>
      <li @click="onSearch('พระรูปเหมือน', '13')">
        <img
          src="../assets/category/รูปเหมือน.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">พระรูปเหมือน</div>
      </li>
      <li @click="onSearch('เหรียญปั๊ม', '14')">
        <img
          src="../assets/category/เหรียญปั๊ม.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">เหรียญปั๊ม</div>
      </li>
      <li @click="onSearch('เหรียญหล่อ เหรียญฉีด', '15')">
        <img
          src="../assets/category/เหรียญหล่อ.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">เหรียญหล่อ เหรียญฉีด</div>
      </li>
      <li @click="onSearch('พระบูชา', '17')">
        <img
          src="../assets/category/พระบูชา.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">พระบูชา</div>
      </li>
      <li @click="onSearch('เครื่องรางของขลัง', '18')">
        <img
          src="../assets/category/เครื่องรางของขลัง.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">เครื่องรางของขลัง</div>
      </li>
      <li @click="onSearch('พระติดรางวัล มีใบรับรอง', '19')">
        <img
          src="../assets/category/ติดรางวัล.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">
          พระติดรางวัล/มีใบรับรอง
        </div>
      </li>
      <li @click="onSearch('พระเบญจภาคี', '20')">
        <img
          src="../assets/category/เบญจาภาคี.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">พระเบญจภาคี</div>
      </li>
      <li @click="onSearch('พระเครื่องอื่นๆ', '21')">
        <img
          src="../assets/category/พระเครื่องอื่นๆ.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">พระเครื่องอื่นๆ</div>
      </li>
      <li @click="onSearch('พระไม่รู้ที่มา', '22')">
        <img
          src="../assets/category/พระไม่รู้ที่มา.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">พระไม่รู้ที่มา</div>
      </li>
      <li @click="onSearch('ของสะสม', '23')">
        <img
          src="../assets/category/ของสะสม.png"
          height="60"
          class="img-category"
        />
        <div class="cat-name">ของสะสม</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "category",
  methods: {
    onSearch(cat_name, cat_id) {
      this.$router.push({
        path: "/category/" + cat_id + "/" + cat_name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cat {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  padding-left: 0;
  width: 100%;

  li {
    // flex-grow: 1;
    flex: 1 0 12%;
    background: #ffffff;
    padding: 15px;
    margin: 5px;
    max-width: 120px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
  }
  li:hover {
    background: gold;
    background: linear-gradient(
      353deg,
      rgba(255, 215, 0, 1) 0%,
      rgba(255, 215, 0, 0.5) 100%
    );
  }
  @media (max-width: 575.98px) {
    li:last-child {
      max-width: 120px !important;
    }
  }
  .cat-name {
    font-family: "Prompt";
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
  }
}
</style>
