<template>
  <div>
    <div id="header" :class="{ 'header--hidden': !showHeader }">
      <div class="container">
        <!-- <div class="container-menu">
        <div class="other-link">
          <router-link to="/" class="d-inline d-md-none mr-3"
            >พระคุณ</router-link
          >
          <router-link to="/" class="d-none d-md-inline">หน้าแรก</router-link>
          <router-link to="/about" class="d-none d-md-inline"
            >เกี่ยวกับเรา</router-link
          >
        </div>
      </div> -->
        <div class="container-header">
          <div class="left">
            <router-link to="/">
              <img src="@/assets/logo-new.png" height="80px" />
            </router-link>
          </div>
          <div class="center">
            <!-- <div class="item">I am centered!</div> -->
            <div class="search">
              <input
                v-model="keyword"
                type="text"
                class="searchTerm"
                placeholder="พิมพ์ค้นหาประกาศ ซื้อ-ขาย"
                @keyup.enter="onSearch"
              />
            </div>
          </div>
          <div class="right">
            <div v-if="isLoggedIn">
              <router-link
                to="/member/notification"
                rel="noopener"
                class="icon-noti"
                title="แจ้งเตือน"
              >
                <i class="fa fa-lg fa-bell"></i>
                <span
                  class="notification-badge badge badge-danger"
                  v-if="memberInfo.noti > 0"
                  >{{ memberInfo.noti }}</span
                >
              </router-link>
              <router-link
                to="/member/buy-sale"
                rel="noopener1"
                class="icon-noti"
                title="ซื้อ - ขาย"
              >
                <i class="fa fa-lg fa-exchange-alt"></i>
                <span
                  class="exchange-badge badge badge-danger"
                  v-if="memberInfo.noti_ex > 0"
                  >{{ memberInfo.noti_ex }}</span
                >
              </router-link>
            </div>
            <div
              class="member d-none d-md-inline"
              style="display:inline-block;margin-top:5px;margin-right:5px;"
              v-if="
                Object.keys(memberInfo).length === 0 &&
                  memberInfo.constructor === Object
              "
            >
              <router-link class="link" to @click.native="login"
                >เข้าสู่ระบบ</router-link
              >
              |
              <router-link to @click.native="register" class="link"
                >สมัครสมาชิก</router-link
              >
            </div>

            <div v-else style="display:inline-block">
              <div
                class="accounts d-none d-md-inline dropdown"
                style="padding-top:5px;display:inline"
                @mouseover="showMenu = true"
                @mouseleave="showMenu = false"
              >
                <img
                  :src="memberInfo.image"
                  v-if="isLoggedIn && memberInfo.image"
                />
                <img
                  src="@/assets/images/user.png"
                  v-if="isLoggedIn && !memberInfo.image"
                />
                <!-- <div class="name" style="display:inline-block">
                      {{ memberInfo.name }}
                    </div> -->

                <div class="dropdown-content" v-show="showMenu">
                  <div class="name" style="display:inline-block">
                    {{ memberInfo.display_name }}
                  </div>
                  <router-link to="/member/profile">โปรไฟล์</router-link>
                  <router-link to="/member/posts">โพสของฉัน</router-link>
                  <router-link to="/member/buy-sale">ซื้อ-ขาย</router-link>
                  <router-link to="/member/favorite">ที่ถูกใจ</router-link>
                  <router-link to="/member/history">ดูล่าสุด</router-link>
                  <router-link to="#" @click.native="logOut"
                    >ออกจากระบบ</router-link
                  >
                </div>
              </div>
              <div class="noti d-none d-md-inline" style="display:inline">
                <router-link to="/member/notification">
                  <span
                    class="mdi mdi-bell"
                    style="font-size:24px;margin-right:10px;"
                  ></span
                ></router-link>
              </div>
              <div class="exchange d-none d-md-inline" style="display:inline">
                <router-link to="/member/notification">
                  <span
                    class="mdi mdi-exchange"
                    style="font-size:24px;margin-right:10px;"
                  ></span
                ></router-link>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-post d-none d-md-inline"
              @click="goToNewPost"
              style="display:inline-block"
            >
              ลงประมูล
            </button>
          </div>
        </div>
      </div>

      <div class="d-inline d-md-none">
        <nav class="nav-btn">
          <router-link to="/" class="nav__link">
            <span class="mdi mdi-home"></span>
            <span class="nav__text">หน้าแรก</span>
          </router-link>
          <router-link to="/member/favorite" class="nav__link">
            <span class="mdi mdi-heart"></span>
            <span class="nav__text">ชื่นชอบ</span>
          </router-link>
          <router-link to="/member/posting" class="nav__link">
            <div class="outer">
              <div class="inner">โพส</div>
            </div>
          </router-link>
          <router-link to="/member/history" class="nav__link">
            <span class="mdi mdi-bell"></span>
            <span class="nav__text">แจ้งเตือน</span>
          </router-link>
          <router-link to="/member/profile" class="nav__link">
            <div class="outer-profile" v-if="isLoggedIn">
              <div class="inner-profile">
                <img :src="memberInfo.image" />
                <span class="mdi mdi-account"></span>
              </div>
            </div>

            <!-- <img :src="`${member.picture}`" v-else /> -->
            <span class="nav__text">ฉัน</span>
          </router-link>
        </nav>
      </div>
    </div>

    <Modal
      :show="showLogin"
      @close="closeLogin"
      :height="'300px'"
      :width="'400px'"
      v-prevent-parent-scroll
    >
      <div slot="header" style="color:gold">
        <span class="mdi mdi-lock"></span>เข้าสู่ระบบ
      </div>
      <div slot="body">
        <form>
          <SocialLogin />
        </form>
      </div>
    </Modal>

    <Modal
      :show="showRegister"
      @close="closeRegister"
      :height="'600px'"
      :width="'400px'"
      v-prevent-parent-scroll
    >
      <div slot="header" style="color:gold">
        <span class="mdi mdi-lock"></span>สมัครสมาชิก
      </div>
      <div slot="body">
        <form>
          <SocialLogin />
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import SocialLogin from "@/components/SocialLogin";
import Modal from "@/components/Modal";
// import VFacebookLogin from "vue-facebook-login-component";
// import GroupMenu from "@/components/GroupMenu";

import AccountService from "../services/Accounts";
import provinceService from "../services/Province";

import categoryService from "../services/Category";

export default {
  name: "Header",
  components: {
    Modal,
    SocialLogin,
    // GroupMenu,
    // VFacebookLogin
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.Account.isLoggedIn;
    },
    memberInfo() {
      return this.$store.state.Account.detail;
    },
    showLogin() {
      return this.$store.state.Account.showLogin;
    },
    showRegister() {
      return this.$store.state.Account.showRegister;
    },
  },
  data() {
    return {
      showHeader: true,
      lastScrollPosition: 0,
      showGroupMenu: false,
      showMenu: false,
      provinces: [],
      keyword: "",
      formRegister: {
        name: "",
        email: "",
        password: "",
        province: "",
      },
      formLogin: {
        email: "",
        password: "",
      },
      // showLogin: false,
      // showRegister: false,
      logined: false,
      member: {
        name: "",
      },
      facebook: {
        FB: {},
        scope: {},
      },
      value: "",
      categorys: {},
      subCategory: [],
    };
  },
  created() {
    // this.getProvince();
    // this.getCategory();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showHeader = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
    getCategory() {
      categoryService.list().then((res) => {
        this.categorys = res.data;
        this.subCategory = this.categorys[0].children;
      });
    },
    getProvince() {
      provinceService.list().then((res) => {
        this.provinces = res.data;
      });
    },
    handleSdkInit({ FB, scope }) {
      console.log(scope);
      console.log(FB);
      this.facebook.scope = scope;
      this.facebook.FB = FB;
    },
    goToNewPost() {
      // this.$store.dispatch("Account/checkLogin");
      // console.log("new post");
      // this.$store.dispatch("Account/showLogin", true);
      // this.$router.push("/posting");
      // console.log(this.userDetail);
      // if (this.memberInfo) {
      this.$router.push("/member/posting");
      // } else {
      //   this.showLogin = true;
      // }
    },
    closeLogin() {
      this.$store.dispatch("Account/showLogin", false);
    },
    onSearch() {
      this.$router
        .push({ path: "/search", query: { keyword: this.keyword } })
        .catch(() => {});
      // this.keyword = "";
    },
    goToCart() {
      this.$router.push("/cart");
    },
    login() {
      // this.formLogin.email = "";
      // this.formLogin.password = "";
      // this.showLogin = true;
      // console.log("login");
      this.$store.dispatch("Account/showLogin", true);
    },
    register() {
      this.formRegister.name = "";
      this.formRegister.email = "";
      this.formRegister.password = "";
      // this.showRegister = true;
      this.$store.dispatch("Account/showLogin", false);
      this.$store.dispatch("Account/showRegister", true);
    },
    closeRegister() {
      this.$store.dispatch("Account/showRegister", false);
    },
    changeToRegister() {
      // this.showRegister = true;
      // this.showLogin = false;
      this.$store.dispatch("Account/showLogin", false);
      this.$store.dispatch("Account/showRegister", true);
    },
    changeToLogin() {
      // this.showRegister = false;
      // this.showLogin = true;
      this.$store.dispatch("Account/showLogin", true);
      this.$store.dispatch("Account/showRegister", false);
    },
    fbLogin(data) {
      //console.log(data);

      this.facebook.FB.api(
        "/me",
        "GET",
        { fields: "id,name,email,picture" },
        (user) => {
          this.member.fbID = user.id;
          this.member.email = user.email;
          this.member.name = user.name;
          this.member.picture = user.picture.data.url;

          //console.log(this.member.picture);

          this.logined = true;
          this.showLogin = false;
          this.showRegister = false;
        }
      );
    },
    fbLogout(data) {
      //console.log(data);
    },
    onRegister() {
      AccountService.register(this.formRegister)
        .then(() => {
          // console.log(res);
          this.$swal({
            title: "ลงทะเบียนเรียบร้อย",
            text: "ระบบกำลังส่งเมลยืนยันไปให้ กรุณาตรวจเช็คอีเมล",
            type: "info",
          });
          this.showRegister = false;
        })
        .catch((err) => {
          // console.log(err);

          this.$swal({
            title: "ข้อมูลไม่ถูกต้อง",
            text: "กรุณาลองใหม่อีกครั้ง",
            type: "wraning",
          });
        });
    },
    doLogin() {
      let _self = this;
      AccountService.login(this.formLogin)
        .then((res) => {
          localStorage.setItem("token", res.data.access_token);

          this.logined = true;

          AccountService.me()
            .then((res) => {
              // this.member.name = res.data.name;
              _self.$store.dispatch("Account/setDetail", res.data);

              _self.formLogin.password = "";
              _self.formLogin.email = "";
              // _self.showLogin = false;
              _self.$store.dispatch("Account/showLogin", false);
            })
            .catch({});

          // console.log(res.data);
        })
        .catch(() => {
          this.$swal("ไม่สามารถเข้าสู่ระบบได้กรุณาลองใหม่");
        });
    },
    goToProfile() {
      this.$router.push("/profile");
    },
    logOut() {
      // alert("logout");

      this.$store.dispatch("Account/logout").then(() => {
        localStorage.removeItem("token");
        this.$router.push("/");
      });
    },
    showSubCategory(index) {
      this.subCategory = this.categorys[index].children;
      // this.$router.push({ path: "/category/" + this.categorys[index].cat_id });
    },
    goToCategoryPost(cat_id) {
      this.$router.push({ path: "/category/" + cat_id });
    },
  },
};
</script>

<style lang="scss">
h3 {
  margin: 0px 10px 0 10px;
}
#header {
  height: 80px;
  background: white;
  //   border-bottom: 1px solid #d1d1d1;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 15px -5px #8a8a8a;

  &.header--hidden {
    box-shadow: none;
    // transform: translate3d(0, -100%, 0);
    opacity: 0;
    transition: opacity 250ms ease-in, visibility 0ms ease-in 250ms;
  }
}

.head1 {
  display: flex;
  justify-content: space-between;
  margin: 0 10px 0 10px;
  .left {
  }
  .right {
  }
}

.el-row {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
#myInput {
  font-family: "Prompt", sans-serif;
  // background-color: #e1f3d8;
  background-image: url("/css/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  width: 80%;
  font-size: 16px;
  padding: 12px 10px 12px 10px;
  // border: 0px solid #ddd;
  border-radius: 5px;
  margin-bottom: 12px;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.other-link {
  // height: 20px;
  font-size: 11px;
  // padding-top: 5px;
  // padding-bottom: 10px;

  a {
    margin-right: 20px;
  }
}

// .search {
//   width: 100%;
//   position: relative;
//   display: flex;
//   flex: 1;
//   .searchTerm {
//     width: 100%;
//     // border: 1px solid #e4e4e4;
//     border: none;
//     background-color: #fdffdc;
//     // border-right: none;
//     padding: 5px 5px 5px 20px;
//     height: 40px;
//     border-radius: 5px;
//     outline: none;
//     color: #5a5a5a;
//     font-size: 12pt;
//     font-family: "Prompt", sans-serif;
//     // padding-right: 65px;
//   }

//   .searchTerm:focus {
//     background-color: #fafafa;
//   }

//   .searchTerm:hover {
//     transition: 0.5s;
//     background-color: #ebebeb;
//   }

//   .search-button {
//     position: absolute;
//     z-index: 2;
//     display: block;
//     color: green;
//     right: 15px;
//     top: 10px;
//     cursor: pointer;
//   }

//   .search-remove-button {
//     position: absolute;
//     z-index: 2;
//     display: block;
//     color: #696969;
//     right: 40px;
//     top: 10px;
//     cursor: pointer;
//   }
// }

// .searchButton {
//   width: 40px;
//   height: 46px;
//   border: 1px solid #00b4cc;
//   background: #00b4cc;
//   text-align: center;
//   color: #139b30;
//   border-radius: 0 5px 5px 0;
//   cursor: pointer;
//   font-size: 28px;
// }

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-menu {
  margin-top: 20px;
}
.btn-post {
  background-color: #ffd900 !important;

  &:hover {
    background: goldenrod !important;
    color: white !important;
  }
  // margin-left: 10px;
}

@media (max-width: 575.98px) {
  // .other-url,
  // .member {
  //   display: none !important;
  // }
  // .btn-post {
  //   width: 60px !important;
  //   height: 30px !important;
  //   font-size: 12px !important;
  // }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  // .other-url,
  // .member {
  //   display: none !important;
  // }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  // .other-url,
  // .member {
  //   display: none !important;
  // }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

.accounts {
  // text-align: left;
  padding-right: 10px;
  // width: 200px !important;
  // height: 30px;
  // background-color: red;
  float: left;
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 150px;

  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-block;
  }
  .name {
    background-color: #d4d4d4;
    border-radius: 10px;
    // margin: 5px;
    width: 100%;
    color: black;
    font-size: 14px;
    // padding-left: 5px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  &:after {
    clear: both;
  }

  .dropdown {
    // display: none;
    position: absolute;
    padding: 5px;
    top: 35px;
    right: 10px;
    z-index: 100;
    background-color: white;
    height: auto;
    width: 200px;
    box-shadow: 0 5px 5px 0 rgba(6, 116, 15, 0.2);
    border: 1px solid #dddddd;
    border-radius: 5px;
    text-align: left;
    li:first-child {
      border-radius: 5px 5px 0 0;
    }
    li:last-child {
      border-radius: 0 0 5px 5px;
    }
    li {
      padding: 10px;
    }
    li:hover {
      background-color: #e5ffd7;
      a {
        color: black;
      }
    }
  }
}

.header-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px 10px 10px;
}

// .search-box {
//   height: auto;
//   overflow: visible;

//   .left {
//     float: none; /* not needed, just for clarification */
//     // background: #e8f6fe;
//     /* the next props are meant to keep this block independent from the other floated one */
//     width: auto;
//     overflow: hidden;
//     padding-right: 5px;
//   }
//   .right {
//     width: auto;
//     float: right;
//     text-align: right;
//     // background: #aafed6;
//   }
// }

.member {
  font-size: 10pt;
}

.nav-btn {
  // font-family: "Prompt" !important;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  overflow-x: auto;
  // overflow: hidden;
  overflow: visible;

  .mdi {
    font-size: 30px;
    padding: 0;
    margin: 0;
    line-height: 25px;
  }

  .nav__text {
    font-size: 14px !important;
    font-family: "Noto Sans Thai" !important;
  }

  .nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    // overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
    position: relative;

    .outer {
      margin: -40px 0 0;
      position: relative;
      background-color: rgb(255, 255, 255);
      width: 80px; /* You can define it by % also */
      height: 80px; /* You can define it by % also */

      // border: 1px solid #b8b8b8;

      border-radius: 50%;
      // box-shadow: rgba(100, 100, 100, 0.2) 0px -1px 0px 0px;

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%; /* Half of the original height */
        top: 0;
        left: 0;
        box-shadow: rgba(100, 100, 100, 0.2) 0px -1px 0px 0px;
        z-index: -1;
        border-radius: 50%;
      }
    }

    .outer-profile {
      z-index: 1;
      margin: -40px 0 0;
      position: relative;
      background-color: rgb(255, 255, 255);
      width: 50px; /* You can define it by % also */
      height: 50px; /* You can define it by % also */

      // border: 1px solid #b8b8b8;

      border-radius: 50%;
      // box-shadow: rgba(100, 100, 100, 0.2) 0px -1px 0px 0px;

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%; /* Half of the original height */
        top: 0;
        left: 0;
        box-shadow: rgba(100, 100, 100, 0.2) 0px -1px 0px 0px;
        z-index: -1;
        border-radius: 50%;
      }

      .inner-profile {
        position: absolute;
        top: 15%;
        left: 15%;
      }
    }

    .inner {
      font-family: "Noto Sans Thai" !important;
      text-align: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #007bff;
      background: linear-gradient(
        125deg,
        rgb(255, 227, 116) 0%,
        rgb(255, 196, 0) 100%
      );
      top: 10%;
      left: 10%; /* of the container */
      width: 80%; /* of the container */
      height: 80%; /* of the container */
      position: relative;
      // border: 1px solid black;
      border-radius: 50%;
      color: white;
      font-size: 18px;
    }
  }

  .nav__link:hover {
    background-color: #eeeeee;
  }

  .nav__link--active {
    color: #009578;
  }

  .nav__icon {
    font-size: 35px !important;
    color: gold;
  }
  .nav__text {
    font-size: 10px;
  }

  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-block;
  }
}

/* Dropdown Button */
.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: auto;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  padding: 5px;
  // display: none;
  text-align: left;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  right: 10px;

  .name {
    padding: 10px;
    font-weight: bold;
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 10px;
  color: goldenrod;
  text-decoration: none;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.content {
  display: flex;
  flex: 1;
  background: #999;
  color: #000;

  .columns {
    display: flex;
    flex: 1;
  }
  .main {
    flex: 1;
    order: 2;
    background: #eee;
  }
  .sidebar-first {
    width: 100px;
    background: #ccc;
    order: 1;
  }
  .sidebar-second {
    width: 200px;
    order: 3;
    background: brown;
  }
}

.main-group {
  // position: relative;

  .select-group {
    background-color: #ffeb3a;
    width: 150px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
    height: 40px;
    overflow: visible;
    i {
      font-size: 24px;
      margin-left: 5px;
    }
  }

  .group-menu {
    position: absolute;
    height: 400px;
    width: 600px;
    left: 30px;
    background-color: white;

    border: 1px solid #e4e4e4;
    border-radius: 5px;
    padding: 10px;
  }
}

.cat-item {
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  list-style-image: url("~@/assets/icons/star.png");
}

.cat-item:nth-child(even) {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.cat-item:nth-child(odd) {
  background-color: #fff;
  border-radius: 5px;
}

.cat-item:hover {
  // background: gold;
  // background: rgb(255, 215, 0);
  background: linear-gradient(
    353deg,
    rgba(255, 215, 0, 1) 0%,
    rgba(255, 215, 0, 0.6197829473586309) 100%
  );
  border-radius: 5px;
}

.list-menu {
  margin-left: 15px;
  list-style-image: url("~@/assets/icons/star.png");
}
.head-box {
  width: 100%;
  height: 100%;
  // border: 1px solid #000;
  /* flex */
  display: flex;

  .left,
  .right {
    // border: 1px solid #000;
    overflow: hidden;
  }
  .left {
    // background: pink;
    min-width: 100px;
    text-align: center;
  }
  .right {
    // background: blue;
    /* flex */
    flex-grow: 1;

    .type-search {
      display: flex;
      .left {
        min-width: 160px;
        max-width: 160px;
        flex-grow: 1;
        // background: blue;
      }
      .center {
        flex-grow: 2;
      }
      .right {
        text-align: right;
        min-width: 250px;
        max-width: 250px;

        flex-grow: 1;
      }
    }
  }
}

.fixed-logo {
  -ms-flex: 0 0 500px;
  flex: 0 0 500px;
}
.or {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.header-box {
  width: 100%;
  height: 80px;
  border: 1px solid #c3c3c3;
  display: flex;

  .left {
    flex-grow: 1;
    display: inline;
  }
  .right {
    flex-grow: 4;
  }
}

.container-header {
  display: flex;
  justify-content: space-between;

  .left {
    // background: red;
    // width: 250px;
  }
  .center {
    // background: blue;
    flex: 1;
    // padding-left: 10px;
    // padding-right: 10px;

    // height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    // display: table-cell;
    // vertical-align: middle;

    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      // display: table;
      // vertical-align: middle;
      .searchTerm {
        width: 100%;
        // border: 1px solid #e4e4e4;
        border: none;
        background-color: #ebebeb;
        // border-right: none;
        padding: 5px 5px 5px 20px;
        height: 40px;
        border-radius: 5px;
        outline: none;
        color: #5a5a5a;
        font-size: 12pt;
        font-family: "Prompt", sans-serif;
        // padding-right: 65px;
      }

      // .searchTerm:focus {
      //   background-color: #fafafa;
      // }

      .searchTerm:focus {
        // transition: 0.5s;

        background-color: #fdffdc;
      }

      // display: flex;
      // align-items: center;
      // justify-content: center;
      // position: relative;
      // display: flex;
      // flex: 1;
    }
  }
  .right {
    // background: green;
    // width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    // vertical-align: middle;
  }

  .link {
    color: black;
  }
}
span.notification-badge {
  position: relative;
  top: -10px;
  right: 10px;
  border: white 1px solid;
  margin-right: -18px;
  font-weight: lighter;
}
span.exchange-badge {
  position: relative;
  top: -10px;
  right: 10px;
  border: white 1px solid;
  margin-right: -18px;
  font-weight: lighter;
}
.icon-noti {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
