import Vue from "vue";
import axios from "axios";
import store from "../store";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URI,
  headers: {
    Accept: "application/json",
    // "content-type": "application/x-www-form-urlencoded;charset=utf-8"
  },
  withCredentials: false,
  timeout: 15000,
});

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

HTTP.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    if (!error.response) {
      Vue.swal({
        icon: "error",
        title: "Network error",
        text:
          "ไม่สามารถติดต่อเซอเวอร์ได้ กรุณาลองใหม่ภายหลังหรือ ติดต่อเจ้าหน้าที่ผู้ดูแล",
      });
      return;
    }
    let {
      config,
      response: { status },
    } = error;
    let originalRequest = config;

    if (status === 401) {
      let token = localStorage.getItem("token");

      if (!token) return Promise.reject(error);

      //console.log('isAlreadyFetchingAccessToken', isAlreadyFetchingAccessToken);

      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        await axios
          .post(process.env.VUE_APP_BASE_URI + "refresh", {
            token: token,
          })
          .then((resp) => {
            isAlreadyFetchingAccessToken = false;
            localStorage.setItem("token", resp.data.access_token);
            originalRequest.headers.Authorization = "Bearer " + resp.data.token;
            onAccessTokenFetched(resp.data.token);
          })
          .catch(() => {
            //console.log('log out');
            localStorage.removeItem("token");
            window.location.href = "/";
            // store.dispatch("Account/LOGOUT").then(() => {
            //   window.location.href = "/login";
            //   // console.log("logout");
            // });
          });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((access_token) => {
          originalRequest.headers.Authorization = "Bearer " + access_token;

          //console.log('origin', originalRequest);

          resolve(axios.request(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    //console.log('login');

    // Vue.swal({
    //   icon: "error",
    //   title: "เกิดข้อผิดพลาด",
    //   text: error.response.data.message,
    // });

    // if (error.response.status == 422) {
    //   // this.validationErrors = error.response.data.errors;
    //   let err = error.response.data;

    //   // err.forEach((obj) => {
    //   let strErr = "";
    //   Object.entries(err).forEach(([key, value]) => {
    //     console.log(`${key} ${value}`);
    //     strErr += value + "\n";
    //   });

    //   Vue.swal({
    //     icon: "error",
    //     title: "เกิดข้อผิดพลาด",
    //     text: strErr,
    //   });
    // }

    return Promise.reject(error.response);
  }
);
export default HTTP;
