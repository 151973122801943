import HTTP from "./http-common";
import moment from "moment";

export default {
  // getAll() {
  //   return HTTP.get("/posts")
  //     .then(function(response) {
  //       return Promise.resolve(response.data);
  //     })
  //     .catch(function(error) {
  //       // console.log(error.response.data);
  //       return Promise.reject(error);
  //     });
  // },
  getDetail(code) {
    return HTTP.get("/post/" + code)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  // firstPage() {
  //   return Api()
  //     .get("/posts/first-page")
  //     .then(function(response) {
  //       return Promise.resolve(response.data);
  //     })
  //     .catch(function(error) {
  //       return Promise.reject(error);
  //       // console.log(error);
  //     });
  // },
  // topHit() {
  //   return Api()
  //     .get("/posts/top-hit")
  //     .then(function(response) {
  //       return Promise.resolve(response.data);
  //     })
  //     .catch(function(error) {
  //       return Promise.reject(error);
  //       // console.log(error);
  //     });
  // },
  list(page, perpage) {
    return HTTP.get("/posts", {
      params: {
        page: page,
        perpage: perpage,
      },
    })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  // listByCategory(cat_id) {
  //   return Api()
  //     .get("/posts/category/" + cat_id)
  //     .then(function(response) {
  //       return Promise.resolve(response.data);
  //     })
  //     .catch(function(error) {
  //       return Promise.reject(error);
  //       // console.log(error);
  //     });
  // },
  create(form) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    //console.log(form);

    let formData = new FormData();
    formData.append("title", form.title);
    formData.append("category", form.category);
    // formData.append("province", form.province);
    formData.append("detail", form.detail);
    formData.append("price_open", form.price_open);
    formData.append("price_close", form.price_close);
    formData.append("price_step", form.price_step);
    formData.append("price_step_display", form.price_step_display);
    formData.append("isInsure", form.isInsure);
    formData.append("insureDate", form.insureDate);
    // formData.append('isReturnComplete', form.isReturnComplete);
    formData.append("close_time", form.close_time);
    formData.append("close_type", form.close_type);
    // formData.append("certificate_code", form.certificate_code);
    //(form);

    // for (var i = 0; i < form.photos.length; i++) {
    //   let file = form.photos.files[i];
    //   formData.append("photos[" + i + "]", file);
    // }

    for (let i = 0; i < form.photos.files.length; i++) {
      let file = form.photos.files[i];

      formData.append("photos[]", file.image);
      formData.append(
        "photos_config[]",
        JSON.stringify({
          id: file.id,
          sort: file.sort,
        })
      );
    }

    return HTTP.post("/post", formData, config)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },

  update(form) {
    //console.log("update");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // console.log(form);

    let formData = new FormData();
    formData.append("post_code", form.post_code);
    formData.append("title", form.title);
    formData.append("category", form.category);
    // formData.append("province", form.province);
    formData.append("detail", form.detail);
    formData.append("price_open", form.price_open);
    formData.append("price_close", form.price_close);
    formData.append("price_step", form.price_step);
    formData.append("price_step_display", form.price_step_display);

    formData.append("isInsure", form.isInsure);
    formData.append("insureDate", form.insureDate);
    // formData.append('isReturnComplete', form.isReturnComplete);
    formData.append(
      "close_time",
      moment(form.close_time).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append("close_type", form.close_type);
    // formData.append("certificate_code", form.certificate_code);
    //console.log(form);

    for (let i = 0; i < form.photos.files.length; i++) {
      let file = form.photos.files[i];
      if (form.photos.files[i].id == null) {
        formData.append("photos[" + i + "]", file.image);
      }
      formData.append(
        "photos_config[" + i + "]",
        JSON.stringify({
          id: file.id,
          sort: file.sort,
        })
      );
    }

    for (let i = 0; i < form.photos.filesDelete.length; i++) {
      let id = form.photos.filesDelete[i];
      formData.append("delete[]", id);
    }

    return HTTP.post("/post/" + form.post_id + "/update", formData, config)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },

  delete(post_id) {
    return HTTP.delete("/post/" + post_id + "/delete")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },

  bid(post_id, price) {
    return HTTP.post("/posts/bid", {
      post_id: post_id,
      price: price,
    })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  fight(post_id, price) {
    return HTTP.post("/posts/fight", {
      post_id: post_id,
      price: price,
    })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  comment(post_id, comment) {
    return HTTP.post("/posts/comment", {
      post_id: post_id,
      comment: comment,
    })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  search(keyword, page, perpage) {
    return HTTP.get("/post/search", {
      params: {
        keyword: keyword,
        page: page,
        perpage: perpage,
      },
    })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  myPost() {
    return HTTP.get("post/my-post")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  newPost() {
    return HTTP.get("post/new")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },

  topPost() {
    return HTTP.get("post/top")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  getbyCat(cat_id) {
    return HTTP.get("post/category/" + cat_id)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  favorite(post_id) {
    return HTTP.post("/post/favorite", {
      post_id: post_id,
    })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  myClose() {
    return HTTP.get("/post/my-close")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  // closeMy() {
  //   return HTTP.get("/post/close-my")
  //     .then(function(response) {
  //       return Promise.resolve(response);
  //     })
  //     .catch(function(error) {
  //       return Promise.reject(error);
  //     });
  // },
  getlast() {
    return HTTP.get("/post/last")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  getPostBy(mem_code) {
    return HTTP.get("/post/by/" + mem_code)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  getPostWinBy(mem_code) {
    return HTTP.get("/post/win/by/" + mem_code)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
};
