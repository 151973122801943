<template>
  <div>
    <div class="container">
      <h4 class="title-header">รายการซื้อ</h4>
      <div v-if="posts.length > 0">
        <ul class="list-group">
          <li v-for="(item, idx) in posts" :key="idx">
            <!-- @click="open(item.post_code)" -->
            <div class="card product">
              <div class="card-header">
                <div class="column3">
                  <div>
                    <strong>วันที่ประมูล</strong> :
                    {{ cvDateTime(item.created_at) }}
                  </div>
                  <div></div>
                  <div>
                    <i class="fas fa-eye" alt="ดู" title="ดู"></i>
                    {{ item.views }}
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="container-img"
                  style="cursor:pointer"
                  @click="open(item.post_code)"
                >
                  <img
                    class="image"
                    v-if="item.title_img"
                    :src="base_img + item.title_img"
                    height="100px"
                  />
                  <img
                    class="image"
                    v-else
                    src="@/assets/images/no-image.png"
                    height="80px"
                  />
                  <div class="text">
                    <div class="column3">
                      <div>
                        <span>{{ item.title }}</span>
                      </div>
                      <div>
                        <span class="closed" v-if="item.status == 'close'"
                          >ปิดประมูล</span
                        >
                        <div v-if="item.close_type == 'price'">
                          <span>ปิดโดยราคา</span>
                          <br />
                          <span class="close-price">{{
                            item.price_close | addComma
                          }}</span>
                        </div>

                        <div v-if="item.close_type == 'time'">
                          <span>ปิดโดยเวลา</span><br />
                          <span>{{ cvDate(item.close_time) }}</span>
                        </div>
                      </div>
                      <div class="last-price">
                        <span class="mini-head">ราคาล่าสุด: </span>
                        <span class="price">{{
                          item.last_price | addComma
                        }}</span>
                        <!-- <div class="up">
                      <i class="fas fa-sort-up"></i>
                      {{ item.last_price - item.price_open }}
                    </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="column3">
                  <div>
                    &nbsp;
                    <span class="mini-head">ราคาเปิด </span>
                    <span class="open-price">{{
                      item.price_open | addComma
                    }}</span>
                    |

                    <span class="mini-head">ราคาเคาะ </span>
                    <span class="close-price">{{
                      item.price_step | addComma
                    }}</span>
                  </div>
                  <div></div>
                  <div>
                    <span>
                      <!-- <router-link to @click.native.stop="editPost(item.post_code)"
                    ><i class="fas fa-edit action-icon"></i>&nbsp;<span
                      class="action"
                      >ขายแล้ว</span
                    ></router-link
                  >
                  | -->
                      <router-link
                        v-if="item.reviewer == null"
                        to
                        @click.native.stop="
                          review(item.post_code, item.close_by, item.title)
                        "
                        custom
                        v-slot="{ navigate }"
                      >
                        <button
                          @click="navigate"
                          role="link"
                          class="btn btn-info btn-sm"
                        >
                          ให้คะแนนผู้ "ซื้อ"
                        </button></router-link
                      >
                    </span>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        ยังไม่มีประกาศ
      </div>
    </div>

    <Modal
      :show="showBid"
      @close="showBid = false"
      :height="'300px'"
      :width="'80%'"
    >
      <div slot="header" style="color:gold;font-size:20px;">
        ให้คะแนนผู้ "ซื้อ"
      </div>
      <div slot="body">
        <div class="review-title">{{ reviewTItle }}</div>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-form-group id="input-group-1" label="คะแนน" label-for="input-1">
            <star-rating v-model="point" :star-size="40"></star-rating>
          </b-form-group>

          <b-form-group id="input-group-2" label="ข้อความ" label-for="input-2">
            <b-form-textarea
              id="textarea"
              v-model="comment"
              placeholder="พิมพ์ข้อความที่นี่..."
              rows="5"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-button type="submit" variant="primary" class="mr-2"
            >บันทึก</b-button
          >
          <b-button type="reset" variant="danger">ล้างค่า</b-button>
        </b-form>
        <!-- <star-rating v-model="rating"></star-rating>
        <b-form-textarea
          id="textarea"
          v-model="comment"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        ></b-form-textarea> -->
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
// import postService from "@/services/posts";
import StarRating from "vue-star-rating";
import memberService from "@/services/Member";
import Modal from "@/components/Modal";
import Review from "@/services/Review";
export default {
  name: "post-close",
  components: {
    Modal,
    StarRating,
  },
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  data() {
    return {
      post_code: "",
      mem_code: "",
      comment: "",
      point: 0,
      showBid: false,
      posts: [],
      reviewTItle: "",
    };
  },
  created() {
    this.getPostClose();
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      let _self = this;

      this.$swal
        .fire({
          title: "ให้คะแนนผู้ซื้อ",
          text: "กรุณายืนยันการบันทึกข้อมูล",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "gold",
          cancelButtonColor: "gray",
          confirmButtonText: "ใช่ยืนยัน",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.value) {
            //
            this.showBid = false;

            var data = {
              post_code: this.post_code,
              close_by: this.close_by,
              point: this.point,
              comment: this.comment,
            };

            Review.buyer(data)
              .then((res) => {
                _self.getPostClose();
                window.location.reload();
              })
              .catch((err) => {});
          }
        });
    },
    onReset() {
      this.point = 0;
      this.comment = "";
      this.post_code = "";
      this.mem_code = "";
    },
    review(post_code, close_by, title) {
      this.point = 0;
      this.comment = "";
      this.post_code = post_code;
      this.close_by = close_by;
      this.reviewTItle = title;
      this.showBid = true;
    },
    getPostClose() {
      memberService
        .post_close()
        .then((res) => {
          this.posts = res.data;
        })
        .catch({});
    },
    goToDetail(code) {
      this.$router.push("/post/" + code);
    },
    cvDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>
<style lang="scss" scoped>
h6 {
  margin-top: 10px !important;
}
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
tr:hover {
  background: #fdf7d4;
}

@media (max-width: 855px) {
  table,
  tbody,
  tr,
  td {
    display: block;
  }
  table {
    border-top: 1px solid #e8e8e8; /* optional */
  }
  thead {
    display: none;
  }
  tr:nth-child(odd) {
    background-color: #f9f9f9; /* optional */
  }
  td {
    position: relative;
    text-align: left;
    padding: 7px 13px; /* optional */
    padding-left: calc(50% + 13px); /* 50% is arbitrary, 13px is optional */
    border: 1px solid #e8e8e8; /* optional */
    border-top: none; /* optional */
  }
  td:before {
    content: attr(data-label);
    position: absolute;
    white-space: nowrap;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%; /* arbitrary */
    text-align: left;
    padding: 7px 13px; /* optional */
    font-weight: 600; /* optional */
    border-right: 1px solid #e8e8e8; /* optional */
  }
}
/* Styles for normal mode (all are optional, presentational styles) */
@media (min-width: 856px) {
  th {
    white-space: nowrap;
    font-weight: 600;
  }
  th,
  td {
    padding: 7px 13px;
    border: 1px solid #e8e8e8;
  }
  th:not(.align-start),
  td:not(.align-start) {
    text-align: center;
  }
}

.list-group {
  width: 100%;
}

.product {
  list-style: none;
  // border: 1px solid gray;
  border-radius: 5px;
  // padding: 10px;
  margin-bottom: 10px;

  .card-header {
    padding: 5px;
    font-size: 14px;
  }

  .card-body {
    padding: 5px;
  }

  .card-footer {
    padding: 5px;
    font-size: 14px;
    background: none;
  }
}
// .panel-img,
// .panel-info,
// .panel-rating,
// .panel-more1 {
//   float: left;
//   margin: 0 10px;
// }

.column3 {
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  height: 100%;
  div {
  }
}
.mini-head {
  color: gray;
}

.container-img {
  display: flex;
  .image {
    width: 100px;
    object-fit: contain;
    align-self: flex-start;
    margin-right: 10px;
  }
  .text {
    flex: 1 1 auto;
    position: relative;
    // position: fixed;
  }
}
.last-price {
  font-size: 24px;
  position: absolute;
  // position: fixed;
  // vertical-align: text-bottom;
  bottom: 0;
  right: 0;
  .price {
    color: goldenrod;
    bottom: 0;
  }
  .up {
    text-align: right;
    color: green;
    font-size: 14px;
  }
}
.open-price {
  color: green;
}
.close-price {
  color: red;
}
.action-icon {
  color: goldenrod;
}
.action {
  color: black;
}
.card-footer {
  border: none;
}
.closed {
  background-color: red;
  color: white;
  font-size: 11px;
  padding: 3px 10px;
  border-radius: 10px;
}
.review-title {
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 15px;
}
</style>
