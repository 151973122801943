<template>
  <div class="container">
    <div class="main-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">หน้าแรก</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="#">{{ nav[0] }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ nav[1] }}
          </li>
        </ol>
      </nav>

      <div class="row" style="margin-bottom:20px;">
        <div class="col-12 col-md-8">
          <div class="product-detail">
            <div v-if="images.length == 1" style="text-align:center">
              <viewer :images="images" class="images">
                <template slot-scope="scope">
                  <img
                    :src="scope.images[0]"
                    :key="scope.images[0]"
                    class="image"
                    width="80%"
                  />
                </template>
              </viewer>
              <!-- <img :src="images[0]" width="80%" @click="showGallery" /> -->
            </div>
            <div v-else>
              <viewer :images="images" class="layout" rebuild>
                <template slot-scope="scope">
                  <!-- {{scope}} -->
                  <div
                    :data-src="src"
                    v-for="(src, idx) in scope.images"
                    :key="src"
                    :class="`img img-${idx + 1} image`"
                    v-bind:style="{
                      backgroundImage: 'url(' + src + ')',
                      backgroundRepeat: 'no-repeat',
                      cursor: 'pointer',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }"
                    @click="showGallery"
                  >
                    <div
                      v-if="images.length > 3 && i == 2"
                      style="color:white;font-size:50px;"
                    >
                      +{{ images.length - 2 }}
                    </div>
                  </div>
                </template>
              </viewer>
            </div>
            <viewer
              :images="images"
              ref="gallery"
              class="images"
              style="display:none"
            >
              <template slot-scope="scope">
                {{ scope }}
                <img
                  v-for="src in scope.images"
                  :src="src"
                  :key="src"
                  @click="showGallery"
                />
                {{ scope.options }}
              </template>
            </viewer>

            <!-- <vue-gallery-slideshow
            :images="images"
            :index="index"
            @close="index = null"
          ></vue-gallery-slideshow> -->

            <div class="name">{{ post.title }}</div>

            <hr />

            <div class="description">
              <h4>รายละเอียด</h4>
              <p v-html="post.detail"></p>
            </div>
          </div>

          <!-- <div class="comment-block">
          <vue-editor v-model="content" :editor-toolbar="customToolbar" />
        </div> -->
        </div>
        <div class="col-12 col-md-4">
          <div class="price-box">
            <div class="price-close">{{ addComma(post.last_price) }}</div>
            <!-- <div class="price-open">เปิด {{addComma(post.price_open)}}</div> -->
            <div class="status-close" v-if="post.status == 'close'">
              ปิดประมูล
            </div>
            <div class="status-open" v-else-if="post.status == 'sell'">
              ขายแล้ว
            </div>
          </div>
          <div class="attribute-box">
            <dl>
              <dt>กลุ่ม</dt>
              <dd>{{ category }}</dd>
              <dt>ราคาเปิด</dt>
              <dd>{{ post.price_open | addComma }}</dd>
              <dt>เคาะขั้นต่ำ</dt>
              <dd>{{ post.price_step }}</dd>
              <dt>ปิดโดย</dt>
              <dd>{{ cvType(post.close_type) }}</dd>
              <dt></dt>
              <dd v-if="post.close_type == 'time'">
                {{ cvTime(post.close_time) }}
              </dd>
              <!-- <dt v-if="account.id == post.user_id">ราคาปิด</dt>
              <dd v-if="account.id == post.user_id">
                {{ post.price_close | addComma }}
              </dd> -->
            </dl>
          </div>
          <div class="auction-box">
            <div
              class="btn-contact"
              v-if="post.status == 'open' && account.mem_code != post.mem_code"
            >
              <div class="input-group mb-3 mt-2">
                <b-form-input
                  type="number"
                  class="form-control"
                  placeholder="อัตโนมัติ หรือกใส่ราคา"
                  v-model="price_bids"
                  size="sm"
                />

                <!-- <select class="custom-select" v-model="price_step">
                  <option
                    v-for="(item, idx) in bidStep"
                    :key="idx"
                    :value="item"
                  >{{ item }}</option>
                </select> -->

                <div class="input-group-append">
                  <button
                    class="btn btn-success btn-sm"
                    type="button"
                    @click="onBid"
                  >
                    ประมูล
                  </button>
                </div>
              </div>
            </div>
            <div style="margin-bottom:10px;font-size:14px;font-weight: bold;">
              รายการประมูล
            </div>
            <div v-if="Bids.length < 1">
              ยังไม่มีผู้ประมูล
            </div>
            <div v-else style="height:400px;overflow-y: scroll;">
              <table width="100%" class="my-table">
                <tr>
                  <th>ชื่อ</th>
                  <th>เวลา</th>
                  <th class="price">ราคา</th>
                </tr>
                <tr v-for="(item, bidx) in Bids" :key="bidx">
                  <td class="name">
                    <router-link
                      class="link"
                      :to="`/member/${item.mem_code}/view`"
                      :alt="'IP:' + item.ip"
                      :title="'IP:' + item.ip"
                      >{{ item.display_name }}</router-link
                    >
                  </td>
                  <td class="time">{{ convertDateSec(item.created_at) }}</td>
                  <td class="price" style="color:green">
                    {{ item.last_price | addComma }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            class="seller-box"
            style="cursor:pointer"
            @click="gotoMember(owner.mem_code)"
          >
            <div style="margin-bottom:10px;font-size:14px;font-weight: bold;">
              เปิดประมูลโดย
            </div>
            <div class="seller">
              <img class="seller-image" :src="owner.image" />
              <div class="seller-detail">
                <div class="name">
                  {{ owner.display_name }}
                  <!-- <router-link :to="'/member/' + owner.code + '/view'">{{
                    owner.name
                  }}</router-link> -->
                </div>
                <div class="detail">
                  เป็นสมาชิกเมื่อ {{ cvDate(owner.created_at) }}
                </div>
              </div>
              <!-- <div class="btn-contact">
                <button class="btn btn-yellow btn-block mt-3">
                  ติดต่อผู้ขาย
                </button>
              </div> -->
            </div>
          </div>
          <div class="product-update-box">
            <dl>
              <dt>อับเดทล่าสุด</dt>
              <dd>{{ convertDate(post.created_at) }}</dd>

              <dt>เริ่มประมูล</dt>
              <dd>{{ convertDate(post.created_at) }}</dd>

              <!-- <dt>ตำแหน่ง</dt>
              <dd>{{ post.province_name }}</dd> -->

              <dt>เลขประกาศ</dt>
              <dd>{{ post.post_code }}</dd>
            </dl>
          </div>
        </div>
      </div>

      <div>
        <CommentBox
          :code="post.post_code"
          :postStatus="post.status"
          :owner="post.mem_code"
        ></CommentBox>
      </div>

      <Modal
        :show="showBid"
        @close="showBid = false"
        :height="'400px'"
        :width="'400px'"
      >
        <div slot="header" style="color:gold;font-size:20px;">ร่วมประมูล</div>
        <div slot="body">
          <span>ราคาประมูล &nbsp;</span>
          <select>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="300">300</option>
            <option value="400">400</option>
            <option value="500">500</option>
          </select>
          <button
            class="btn btn-success btn-block mt-3"
            @click="onBid"
            v-if="post.status == 'open'"
          >
            ประมูล
          </button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
// import VueGallerySlideshow from "vue-gallery-slideshow";
import postService from "../services/posts";
import bidsService from "../services/Bids";
import moment from "moment";
import Modal from "@/components/Modal";
import CommentBox from "@/components/CommentBox";

import historyService from "../services/history";
// import { VueEditor } from "vue2-editor";
// import { Editor, EditorContent, EditorMenuBar } from "tiptap";

// import { VueEditor } from "vue2-editor";

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);

export default {
  name: "detail",
  components: {
    // VueGallerySlideshow,
    Modal,
    CommentBox,
    // VueEditor
    // EditorMenuBar,
    // EditorContent,
  },
  computed: {
    account() {
      return this.$store.getters["Account/detail"];
    },
    isLogined() {
      return this.$store.getters["Account/isLoggedIn"];
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    //console.log(this.post);
  },
  beforeDestroy() {
    // this.editor.destroy();
  },
  data() {
    return {
      fight: true,
      code: "",
      nav: [],
      Bids: [],
      bidStep: [],
      images: [],
      index: null,
      post: {},
      owner: {},
      showBid: false,
      price_step: 0,
      price_bids: 0,
      content: "",
      category: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      options: { url: "data-source" },
      price_step_display: "",

      total: "",
      minutes: "--",
      seconds: "--",
      interval: 0,
    };
  },
  async created() {
    let code = this.$route.params.code;
    // console.log(this.$route);
    this.code = code;
    await this.getDetail(code);
  },
  methods: {
    cvTime(time) {
      return moment(time).format("DD/MM/YYYY HH:mm");
    },
    cvType(type) {
      if (type == "price") {
        return "ปิดโดยราคา";
      } else {
        return "ปิดโดยเวลา";
      }
    },
    str_pad_left(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    },
    tick() {
      var minutes = Math.floor(this.total / 60);
      var seconds = this.total - minutes * 60;
      this.minutes = this.str_pad_left(minutes, "0", 2);
      this.seconds = this.str_pad_left(seconds, "0", 2);

      if (this.total <= 0) {
        // expire
        clearInterval(this.interval);
        this.fight = false;
        // this.$bus.$emit("Timer/stop");
      }

      this.total -= 1;
    },
    gotoMember(owner_code) {
      this.$router.push("/member/" + owner_code + "/view");
    },
    convertTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm");
    },
    showGallery() {
      // alert('show')
      // this.$refs.viewer.show();
      // console.log(this.$refs.gallery);
      // this.$viewer.show()
      // const vuer = this.$el.querySelector('.gallery').$vuer
      //   vuer.show()

      const viewer = this.$refs.gallery.$viewer;
      viewer.show();
    },
    cvDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getDetail(code) {
      postService
        .getDetail(code)
        .then((res) => {
          // console.log(res);
          this.post = res.data.post;

          var diff = moment(this.post.fight_time, "YYYY-MM-DD HH:mm:ss").diff(
            moment().format("YYYY-MM-DD HH:mm:ss"),
            "secounds"
          );

          // console.log(diff);

          this.total = diff / 1000;

          if (this.total < 0) {
            this.fight = false;
          } else {
            this.interval = setInterval(() => {
              this.tick();
            }, 1000);
          }

          this.owner = res.data.owner;
          this.price_step = this.post.price_step;
          this.price_bids = this.price_step;

          this.nav.push(res.data.parent.cat_name);
          this.nav.push(res.data.category.cat_name);

          this.category =
            res.data.parent.cat_name + " > " + res.data.category.cat_name;

          if (this.isLogined)
            historyService.add(this.post.id).then((res) => {}); // save history

          bidsService.list(this.post["post_code"]).then((res) => {
            this.Bids = res.data.data;
            //console.log("bid", res.data);
          });

          this.images = [];

          res.data.images.forEach((item) => {
            //console.log(item);
            this.images.push(
              process.env.VUE_APP_BASE_IMG_UPLOAD + item.img_path
            );
          });
        })
        .catch((err) => {
          this.$router.push("/notfound");
        });
    },
    onClick(i) {
      this.index = i;
    },
    ranImage() {
      let number = Math.floor(Math.random() * 3) + 1;
      let img = "pra00" + number + ".jpg";
      // console.log
      return img;
    },
    ranProvince() {
      let number = Math.floor(Math.random() * 77);
      return this.province_th[number];
    },
    ranPrice() {
      let number = Math.floor(Math.random() * (10000 - 5000) + 5000);
      return Number(number).toLocaleString();
    },
    addComma(number) {
      if (number)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "-";
    },
    convertDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    convertDateSec(date) {
      return moment(date).format("DD/MM/YY HH:mm:ss");
    },
    loadBids(post_code) {
      bidsService.list(post_code).then(() => {
        this.getDetail(post_code);
      });
    },

    onFight() {
      this.$swal({
        title: "กรุณากรอกราคาที่จะสู้",
        html:
          "ราคาต้องมากกว่าราคาปิด ปัจจุบัน : " +
          this.addComma(this.post.last_price),
        input: "text",
        confirmButtonText: "สู้ประมูล",
        confirmButtonColor: "#FF0000",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          bidsService
            .fight({ post_code: this.post.post_code, price: result.value })
            .then((res) => {
              this.loadBids(this.post.post_code);

              this.interval = setInterval(() => {
                this.tick();
              }, 1000);
            })
            .catch({});
          // this.$swal(
          //   "Deleted",
          //   "You successfully deleted this file",
          //   "success"
          // );
        } else {
          // this.$swal("Cancelled", "Your file is still intact", "info");
        }
      });
    },

    onBid() {
      // this.price_step = Math.ceil(this.price_step / 100) * 100;

      if (!this.isLogined) {
        this.$store.dispatch("Account/showLogin", true);
      } else {
        if (
          this.price_bids <= 0 ||
          this.price_bids == "" ||
          this.price_bids < this.price_step
        ) {
          this.$swal.fire(
            "กรอกราคาประมูล",
            "กรุณากรอกราคาประมูลให้ถูกต้อง",
            "warning"
          );
          this.price_bids = this.price_step;
          return;
        }

        if (parseInt(this.price_bids) > parseInt(this.post.price_open) * 5) {
          this.$swal.fire(
            "ราคาเคาะไม่ถูกต้อง",
            "ราคาเคาะต้องไม่เกิน 5 เท่าของราคาเปิดประมูล",
            "warning"
          );
          this.price_bids = parseInt(this.post.price_open) * 5;
          return;
        }

        this.$swal({
          title: "ยืนยันร่วมประมูล?",
          html:
            "ราคาจะเพิ่มไปอีก <strong style='color:green'>" +
            this.addComma(this.price_bids) +
            "</strong> <br/> จากราคา <strong>" +
            this.addComma(this.post.last_price) +
            "</strong> เป็น <strong style='color:red'>" +
            this.addComma(
              parseInt(this.price_bids) + parseInt(this.post.last_price)
            ) +
            "</strong>",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ใช่ ประมูลเลย",
          cancelButtonText: "ไว้ก่อน",
        }).then((result) => {
          if (result.value) {
            let _self = this;
            bidsService
              .bid({
                post_code: this.post.post_code,
                price: this.price_bids,
                price_last: this.post.last_price,
              })
              .then(() => {
                this.$swal({
                  position: "top",
                  icon: "success",
                  title: "ประมูลสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.loadBids(_self.post.post_code);
              })
              .catch((err) => {
                this.$swal({
                  position: "top",
                  icon: "error",
                  title: "ประมูลไม่สำเร็จ",
                  text: err.data.error,
                  showConfirmButton: true,
                  timer: 1500,
                });
                this.loadBids(_self.post.post_code);
              });
            //   Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
      }
    },

    // goToDetail() {
    //   this.$router.push("/detail");
    // }
  },
};
</script>

<style lang="scss" scoped>
.flex_container {
  display: flex;
  width: 100%;
}
.flex_item_left {
  flex: 1;
  // background: lightgreen;
}
.flex_item_right {
  width: 100px;

  // background: lightblue;
}

.price-box {
  border-radius: 10px;
  // border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  text-align: center;
  padding: 5px;
  font-size: 50px;

  .price-open {
    color: gray;
    font-size: 25px;
  }
  .price-close {
    color: goldenrod;
  }
  .other {
    font-size: 12px;
    color: gray;
  }
  .status-close {
    font-size: 40px;
    color: white;
    background: #ff0101;
    border-radius: 20px;
  }
  .status-open {
    font-size: 40px;
    color: white;
    background: #27c200;
    border-radius: 20px;
  }
}

.attribute-box {
  dl {
    margin-bottom: 50px;
    dt {
      // background: #5f9be3;
      // color: #fff;
      float: left;
      font-weight: bold;
      margin-right: 10px;
      padding: 5px;
      width: 100px;
    }
    dd {
      margin: 2px 0;
      padding: 5px 0;
    }
  }
}

.product-update-box {
  dl {
    margin-top: 20px;
    font-size: 12px;
    dt {
      // background: #5f9be3;
      // color: #fff;
      float: left;
      // font-weight: lighter;
      margin-right: 10px;
      padding: 3px;
      width: 100px;
    }

    dd {
      margin: 2px 0;
      padding: 2px 0;
    }
  }
}

.seller-box {
  background-color: white;
  box-shadow: 0 0px 10px 0px rgba(6, 116, 15, 0.2);
  // height: 400px;
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}

.seller-image {
  vertical-align: top;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.seller-detail {
  margin-left: 10px;
  display: inline-block;
  .name {
    font-size: 20px;
    color: goldenrod;
  }
  .detail {
    font-size: 14px;
    color: gray;
  }
}

.top-banner {
  font-size: 30px;
  height: 120px;
  border: 1px solid #cecece;
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.product-detail {
  background-color: white;
  // box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);

  // border: 1px solid #e4e4e4;
  padding: 10px;
  border-radius: 10px;
  // margin-right: 20px;

  .name {
    margin-top: 20px;
    color: black;
    font-size: 35px;
  }
  .description {
    h4 {
      font-family: "Prompt";
      color: goldenrod;
    }
    line-height: 1.8;

    font-family: "tahoma";
  }
}

.grid-container {
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.9rem;
}

.card-product {
  background-color: white;
  cursor: pointer;
  // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #ececec;
  transition: 0.3s;
  border-radius: 5px;
  width: 100%;
  // height: 250px;

  img {
    min-height: 200px;
    border-radius: 5px 5px 0 0;
  }

  .detail {
    padding: 10px;

    .title {
      color: #292929;
      font-size: 20px;
      margin-bottom: 5px;
    }
    .province {
      color: gray;
    }

    .parent {
      display: flex;
      align-items: center;
      .price {
        font-size: 16px;
        color: #000000;
        flex-grow: 1;
      }
      .last-price {
        font-size: 20px;
        color: goldenrod;
      }
    }
  }
}

.card-product:hover {
  box-shadow: 0 8px 16px 0 rgba(6, 116, 15, 0.2);
}

.other-product {
  margin-top: 50px;
}

.layout {
  width: 100%;
  height: 450px;

  display: grid;
  grid-gap: 10px 10px;
  grid-template-rows: repeat(4, minmax(max-content, 1fr));
  grid-template-columns: repeat(3, minmax(max-content, 1fr));
  grid-template-areas:
    "a a b"
    "a a b"
    "a a c"
    "a a c";
}

// .img {
//   width: 300px;
// }

.img-1 {
  grid-area: a;
  background-position: "top";
}
.img-2 {
  grid-area: b;
  background-position: "top";
}
.img-3 {
  grid-area: c;
  background-position: "top";
}
// .img-4 {
//   grid-area: d;
//   background-position: "top";
// }
// .img-5 {
//   grid-area: e;
//   background-position: "top";
// }

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.auction-box {
  width: 100%;
  background-color: white;
  box-shadow: 0 0px 10px 0px rgba(6, 116, 15, 0.2);
  // height: 400px;
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-bottom: 10px;
}
.my-table {
  font-size: 14px;
  .name {
    font-weight: bold;
  }
  .time {
  }
  .price {
    text-align: right;
  }
}

.comment-block {
  margin-top: 10px;
  padding: 10px;

  background-color: #fff;
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
  // border: 1px solid #e4e4e4;
  padding: 20px;
  border-radius: 10px;
}

.ql-editor {
  min-height: 100px;
}
.link {
  color: rgb(67, 48, 0);
}
</style>
