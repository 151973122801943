<template>
  <div class="signup-buttons">
    <!-- <div id="fb-root"></div> -->
    <div class="row">
      <div class="col">
        <a href="#" class="btn-line-login" @click.prevent="loginWithLine"
          >ล๊อกอินโดย LINE</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!-- <vue-google-signin-button
          :clientId="
            '147385082977-vn0sb8odkb1b5hfk1a2satqk8sb4e5cg.apps.googleusercontent.com'
          "
          @success="onSignInSuccess"
          @failure="onSignInFailure"
        ></vue-google-signin-button> -->
        <!-- <button
          type="button"
          id="google-login-button"
          @click="handleGoogleSignIn"
        >
          Sign in with Google
        </button> -->
      </div>
    </div>
    <!-- <div class="row">
      <line-login-button
        client-id="11111"
        callback-uri="http://localhost:8080"
        client-secret="secret"
        @result="result"
        add-friend
        friend-required
      ></line-login-button>
    </div> -->

    <div class="row">
      <!-- <div class="col">
        <a href="#" class="google-signup" @click.prevent="loginWithGoogle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            aria-hidden="true"
          >
            <title>Google</title>
            <g fill="none" fill-rule="evenodd">
              <path
                fill="#4285F4"
                d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
              ></path>
              <path
                fill="#34A853"
                d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
              ></path>
              <path
                fill="#FBBC05"
                d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
              ></path>
              <path
                fill="#EA4335"
                d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
              ></path>
            </g>
          </svg>
          Google
        </a>
      </div> -->
      <!-- <div class="col">
        <a href="#" class="facebook-signup" @click.prevent="loginWithFacebook">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="#3578E5"
          >
            <path
              d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
            />
          </svg>
          Facebook
        </a>
      </div> -->
    </div>

    <!-- <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading> -->
  </div>
</template>

<!-- <script src="https://accounts.google.com/gsi/client" async defer></script> -->
<script>
// import Loading from "vue-loading-overlay";
// // Import stylesheet
// import "vue-loading-overlay/dist/vue-loading.css";

import { initFbsdk } from "@/config/facebook_oAuth.js";
import accountService from "@/services/Accounts";
import axios from "axios";
// import router from '@/router/router'
// import "@team-decorate/dist/vue-line-login.css";
// import VueLineLogin from "@team-decorate/vue-line-login";
export default {
  name: "login_signup_social",
  // components: {
  //   Loading,
  //   // VueLineLogin => ,
  // },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    // gapi.load("auth2", () => {
    //   gapi.auth2
    //     .init({
    //       client_id: "yourClientId",
    //     })
    //     .then(() => {
    //       gapi.signin2.render("google-login-button", {
    //         scope: "profile email",
    //         width: 240,
    //         height: 50,
    //         longtitle: true,
    //         theme: "dark",
    //         onsuccess: this.onSignInSuccess,
    //         onfailure: this.onSignInError,
    //       });
    //     });
    // });
  },
  created() {},
  methods: {
    onSignInSuccess(googleUser) {
      // Handle the successful sign-in
      console.log("Signed in successfully", googleUser);
    },
    onSignInFailure(error) {
      // Handle the sign-in failure
      console.error("Sign-in failed", error);
    },
    result(res) {
      console.log(res);
    },
    loginWithGoogle() {
      let _self = this;
      this.isLoading = true;
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          // on success do something
          // console.log('GoogleUser', GoogleUser)
          // console.log('getId', GoogleUser.getId())
          // console.log("getBasicProfile", GoogleUser.getBasicProfile());
          let profile = GoogleUser.getBasicProfile();
          //console.log("getBasicProfile", profile);
          var userInfo = {
            type: "google",
            id: profile.getId(),
            email: profile.getEmail(),
            name: profile.getName(),
            image: profile.getImageUrl(),
          };
          // this.$store.commit('setLoginUser', userInfo)
          // router.push('/home')

          accountService.loginGoogle(userInfo).then((res) => {
            localStorage.setItem("token", res.data.access_token);

            // this.logined = true;

            accountService
              .me()
              .then((res) => {
                // this.member.name = res.data.name;
                _self.$store.dispatch("Account/setDetail", res.data);

                // _self.showLogin = false;
                _self.$store.dispatch("Account/showLogin", false);
                this.isLoading = false;
              })
              .catch({});
          });
        })
        .catch((error) => {
          //("error", error);
          this.isLoading = false;
        });
    },
    loginWithLine() {
      // var w = 400;
      // var h = 500;
      // var left = screen.width / 2 - w / 2;
      // var top = screen.height / 2 - h / 2;
      // window.open(
      //   "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657686901&redirect_uri=" +
      //     process.env.VUE_APP_LINE_REDIRECT +
      //     "&state=12345abcde&scope=profile%20openid%20email&nonce=09876xyz",
      //   "lineLogin",
      //   "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      //     w +
      //     ", height=" +
      //     h +
      //     ", top=" +
      //     top +
      //     ", left=" +
      //     left
      // );
      window.open(
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657686901&redirect_uri=" +
          process.env.VUE_APP_LINE_REDIRECT +
          "&state=12345abcde&scope=profile%20openid%20email&nonce=09876xyz",
        "_self"
      );
    },
    loginWithFacebook() {
      let _self = this;
      this.isLoading = true;
      window.FB.login(
        (response) => {
          // console.log(response);

          if (!response.authResponse) {
            this.isLoading = false;
            return;
          }

          let profile = response.authResponse;

          var userInfo = {
            type: "facebook",
            id: "",
            email: "",
            name: "",
            image: "",
          };

          // https://graph.facebook.com/me?fields=id,name,email

          // 'https://graph.facebook.com/'+profile.userID+'/picture?type=large&access_token='+profile.accessToken

          axios
            .get(
              "https://graph.facebook.com/v8.0/me?&fields=id,name,email,picture.width(640).height(640)&access_token=" +
                profile.accessToken
            )
            .then((res) => {
              // console.log(res);

              userInfo.id = res.data.id;
              userInfo.email = res.data.email;
              userInfo.name = res.data.name;
              userInfo.image = res.data.picture.data.url;

              // console.log('login')

              accountService.loginFacebook(userInfo).then((res) => {
                // console.log('login')

                localStorage.setItem("token", res.data.access_token);

                // this.logined = true;

                accountService
                  .me()
                  .then((res) => {
                    // this.member.name = res.data.name;
                    _self.$store.dispatch("Account/setDetail", res.data);

                    _self.$store.dispatch("Account/showLogin", false);

                    this.isLoading = false;
                  })
                  .catch({});
              });
            });

          // var userInfo = {
          //   loginType: 'fb',
          //   fb: response
          // }
          // console.log('fb response', response)
          // this.$store.commit('setLoginUser', userInfo)
          // router.push('/home')
        },
        { scope: "email", return_scopes: true }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.facebook-signup,
.google-signup {
  color: #031b4e;
  background: #f2f8ff;
  border: 1px solid rgba(0, 105, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  text-align: center;
  position: inherit;
}
.btn-line-login {
  color: white;
  background: #00b900;
  // border: 1px solid rgba(0, 105, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  text-align: center;
  position: inherit;
}
</style>
