<template>
  <div class="container">
    <!-- {{ Profile }} -->
    <!-- <b-card no-body>
      <b-tabs pills card vertical>
        <b-tab title="Tab 1" active
          ><b-card-text>Tab contents 1</b-card-text></b-tab
        >
        <b-tab title="Tab 2"><b-card-text>Tab contents 2</b-card-text></b-tab>
        <b-tab title="Tab 3"><b-card-text>Tab contents 3</b-card-text></b-tab>
      </b-tabs>
    </b-card> -->
    <!-- <h3>แก้ไขข้อมูลส่วนตัว</h3>
    <hr /> -->
    <div class="row">
      <!-- left column -->
      <!-- <div class="col-md-3">
        <div class="text-center">
          <img
            src="//placehold.it/150"
            class="avatar img-circle"
            alt="avatar"
          />
          <h6>อับโหลดรูปภาพ</h6>

          <input type="file" class="form-control" />
        </div>
      </div> -->

      <!-- edit form column -->
      <div class="col-md-9 personal-info">
        <h3>ข้อมูลส่วนตัว</h3>

        <form class="form-horizontal" role="form">
          <div class="form-group">
            <label class="col-lg-3 control-label">ชื่อ</label>
            <div class="col-lg-8">
              <input
                class="form-control"
                type="text"
                v-model="form.display_name"
                readonly
              />
            </div>
          </div>
          <!-- <div class="form-group">
            <label class="col-lg-3 control-label">อีเมล์</label>
            <div class="col-lg-8">
              <input
                class="form-control"
                type="text"
                v-model="form.email"
                readonly
              />
            </div>
          </div> -->
          <div class="form-group">
            <label class="col-lg-3 control-label">จังหวัด</label>
            <div class="col-lg-8">
              <div class="ui-select">
                <select class="form-control" v-model="form.province_id">
                  <option
                    :value="item.id"
                    v-for="(item, pidx) in Provinces"
                    :key="pidx"
                    >{{ item.name_th }}</option
                  >
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-lg-3 control-label">ชื่อ</label>
            <div class="col-lg-8">
              <input
                class="form-control"
                type="text"
                v-model="form.first_name"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-lg-3 control-label">นามสกุล</label>
            <div class="col-lg-8">
              <input
                class="form-control"
                type="text"
                v-model="form.last_name"
              />
            </div>
          </div>
          <!-- <div class="form-group">
            <label class="col-lg-12 control-label"
              >เลขบัตรประชาชน/Passport</label
            >
            <div class="col-lg-8">
              <input class="form-control" type="text" v-model="form.license" />
            </div>
          </div> -->
          <div class="form-group">
            <label class="col-lg-3 control-label">ธนาคาร</label>
            <div class="col-lg-8">
              <input
                class="form-control"
                type="text"
                v-model="form.bank_name"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-lg-3 control-label">เลขบัญชี</label>
            <div class="col-lg-8">
              <input
                class="form-control"
                type="text"
                v-model="form.bank_number"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-lg-3 control-label">ที่อยู่จัดส่ง</label>
            <div class="col-lg-8">
              <textarea
                rows="5"
                class="form-control"
                type="text"
                v-model="form.address"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label"></label>
            <div class="col-md-8">
              <input
                type="button"
                class="btn btn-warning"
                value="บันทึก"
                @click="onUpdateProfile"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import postService from "../services/posts";

// import AccountService from "../services/Accounts";
import ProvinceService from "../services/Province";

import MemberService from "../services/Member";

export default {
  data() {
    return {
      MyPost: [],
      Provinces: [],
      Profile: {},
      form: {},
    };
  },
  async created() {
    // this.getMyPost();

    await this.getProvince();

    await this.getProfile();
  },
  methods: {
    getProfile() {
      MemberService.getProfile()
        .then((res) => {
          this.form = res.data;

          // // // console.log(res);
          // this.form.display_name = this.Profile.display_name;
          // // // this.form.email = this.Profile.email;
          // this.form.province_id = this.Profile.province_id;
          // this.form.first_name = this.Profile.first_name;
          // this.form.last_name = this.Profile.last_name;
        })
        .catch({});
    },
    getMyPost() {
      postService.myPost().then((res) => {
        this.MyPost = res.data;
      });
    },
    getProvince() {
      ProvinceService.list()
        .then((res) => {
          // console.log(res);
          this.Provinces = res.data;
        })
        .catch({});
    },
    signOut() {
      // this.
      localStorage.removeItem("token");
      this.$router.push("/");
    },
    convertDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    open(code) {
      this.$router.push("/post/" + code);
    },
    onUpdateProfile() {
      MemberService.updateProfile(this.form)
        .then(() => {
          this.$swal.fire("อับเดทข้อมูล", "ปรับปรุงข้อมูลเรียบร้อย", "success");
        })
        .catch({});
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-container:hover .image {
  opacity: 0.3;
}

.image-container:hover .middle {
  opacity: 1;
}
</style>
