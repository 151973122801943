<template>
  <div>
    <div class="container">
      <div class="profile">
        <img class="img" :src="Info.image" />
        <div class="text">
          <h5>ชื่อ {{ Info.name }}</h5>
          <h6>เลขที่สมาชิก {{ Info.mem_code }}</h6>
          <div style="font-size:12px;">{{ Info.email }}</div>
          <div style="font-size:12px;">
            เป็นสมาชิกเมื่อ {{ cvDate(Info.created_at) }}
          </div>
          <!-- <div style="font-size:12px;">ติดตาม 10</div>
          <div style="font-size:12px;">กำลังตาม 11</div> -->
        </div>
        <div style="width:200px">
          คะแนนรวมการขาย
          <br />
          <star-rating
            v-model="rate_seller"
            :increment="0.1"
            :star-size="20"
            :read-only="true"
            :active-color="['#ae0000', '#03AC13']"
          ></star-rating>
          <div style="font-size:12px;">
            จากเพื่อนสมาชิก {{ total_seller }} รายการ
          </div>
        </div>
        <div style="width:200px">
          คะแนนรวมการซื้อ
          <br />

          <star-rating
            v-model="rate_buyer"
            :increment="0.1"
            :star-size="20"
            :read-only="true"
            :active-color="['#ae0000', '#1E90FF']"
          ></star-rating>

          <div style="font-size:12px;">
            จากเพื่อนสมาชิก {{ total_buyer }} รายการ
          </div>
        </div>

        <!-- <h1 class="display-3">A Warm Welcome!</h1>
      <p class="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, ipsam, eligendi, in quo sunt possimus non incidunt odit vero aliquid similique quaerat nam nobis illo aspernatur vitae fugiat numquam repellat.</p>
      <a href="#" class="btn btn-primary btn-lg">Call to action!</a> -->
      </div>

      <div class="row m-3">
        <b-tabs content-class="mt-3" style="width:100%">
          <b-tab title="เปิดประมูล" active>
            <div class="content">
              <div class="grid-container" v-if="posts.length > 0">
                <div
                  class="card-product"
                  v-for="(item, nidx) in posts"
                  :key="'list' + nidx"
                  @click="goToDetail(item.post_code)"
                >
                  <div class="img-post">
                    <img
                      v-if="item.title_img && !null"
                      :src="base_img + item.title_img"
                      alt="รูป"
                    />
                    <img
                      v-else
                      src="@/assets/images/noimage.png"
                      alt="ไม่พบรูปภาพ"
                      width="100%"
                    />
                    <div class="close-label" v-if="item.status == 'close'">
                      ปิดประมูล
                    </div>
                  </div>
                  <div class="detail">
                    <div class="title">{{ item.title }}</div>
                    <div class="province">{{ item.province_name }}</div>
                    <div class="price">
                      <div class="open">
                        เปิด {{ item.price_open | addComma }}
                      </div>
                      <div class="last">
                        ราคาปัจจุบัน {{ item.last_price | addComma }}
                      </div>
                    </div>
                    <!-- <div class="parent"></div> -->
                  </div>
                </div>
              </div>
              <div v-else>
                ยังไม่มีประกาศ
              </div>
            </div>
          </b-tab>
          <b-tab title="ชนะประมูล">
            <div class="content">
              <div class="grid-container" v-if="postsWin.length > 0">
                <div
                  class="card-product"
                  v-for="(item, nidx) in postsWin"
                  :key="'cb' + nidx"
                  @click="goToDetail(item.post_code)"
                >
                  <div class="img-post">
                    <img
                      v-if="item.title_img && !null"
                      :src="base_img + item.title_img"
                      alt="รูป"
                    />
                    <img
                      v-else
                      src="@/assets/images/noimage.png"
                      alt="ไม่พบรูปภาพ"
                      width="100%"
                    />
                    <div class="close-label" v-if="item.status == 'close'">
                      ปิดประมูล
                    </div>
                  </div>
                  <div class="detail">
                    <div class="title">{{ item.title }}</div>
                    <div class="province">{{ item.province_name }}</div>
                    <div class="price">
                      <div class="open">
                        เปิด {{ item.price_open | addComma }}
                      </div>
                      <div class="last">
                        ราคาปัจจุบัน {{ item.last_price | addComma }}
                      </div>
                    </div>
                    <!-- <div class="parent"></div> -->
                  </div>
                </div>
              </div>
              <div v-else>
                ยังไม่มีประกาศ
              </div>
            </div></b-tab
          >

          <b-tab :title="'รีวิวการขาย (' + total_seller + ')'">
            <div>
              <div
                class="be-comment"
                v-for="(item, idx) in seller"
                :key="'s' + idx"
              >
                <div class="be-img-comment">
                  <a href="blog-detail-2.html">
                    <img :src="item.image" alt="" class="be-ava-comment" />
                  </a>
                </div>
                <div class="be-comment-content">
                  <span class="be-comment-name">
                    <a href="blog-detail-2.html">{{ item.display_name }}</a>
                    <star-rating
                      :rating="item.point"
                      :star-size="20"
                      :read-only="true"
                    ></star-rating>
                  </span>

                  <span class="be-comment-time">
                    <i class="fa fa-clock-o"></i>
                    {{ cvDate(item.review_at) }}
                  </span>

                  <div class="be-comment-url">
                    <router-link :to="'../../post/' + item.post_code">{{
                      item.title
                    }}</router-link>
                  </div>

                  <p class="be-comment-text">
                    {{ item.comment }}
                  </p>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="'รีวิวการซื้อ (' + total_buyer + ')'">
            <div>
              <div
                class="be-comment"
                v-for="(item, idx) in buyer"
                :key="'s' + idx"
              >
                <div class="be-img-comment">
                  <a href="blog-detail-2.html">
                    <img :src="item.image" alt="" class="be-ava-comment" />
                  </a>
                </div>
                <div class="be-comment-content">
                  <span class="be-comment-name">
                    <a href="blog-detail-2.html">{{ item.display_name }}</a>
                    <star-rating
                      :rating="item.point"
                      :star-size="20"
                      :read-only="true"
                    ></star-rating>
                  </span>

                  <span class="be-comment-time">
                    <i class="fa fa-clock-o"></i>
                    {{ cvDate(item.review_at) }}
                  </span>

                  <div class="be-comment-url">
                    <router-link :to="'../../post/' + item.post_code">{{
                      item.title
                    }}</router-link>
                  </div>

                  <p class="be-comment-text">
                    {{ item.comment }}
                  </p>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import moment from "moment";
import accountService from "../../services/Accounts";
import memberService from "../../services/Member";
import reviewService from "../../services/Review";
import postService from "../../services/posts";

export default {
  name: "member-info",
  components: {
    StarRating,
  },
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  data() {
    return {
      // member_id: null
      rate_seller: 0,
      rate_buyer: 0,
      Info: {},
      post_list: [],
      post_closed_bid: [],
      seller: [],
      total_seller: 0,
      buyer: [],
      total_buyer: 0,
      posts: [],
      postsWin: [],
    };
  },
  created() {
    // console.log(this.$route.params.id);
    let code = this.$route.params.id;

    // console.log(code)
    this.getInfo(code);
    // this.getList(code);
    // this.getClosedBid(code);
    this.getReviewBuyer(code);
    this.getReviewSeller(code);

    this.getPostBy(code);
    this.getPostWinBy(code);
  },
  methods: {
    getInfo(code) {
      memberService.getInfo(code).then((res) => {
        this.Info = res.data;
      });
    },
    getList(code) {
      memberService.getList(code).then((res) => {
        this.post_list = res.data;
      });
    },
    getClosedBid(code) {
      memberService.getClosedBid(code).then((res) => {
        this.post_closed_bid = res.data;
      });
    },
    cvDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    goToDetail(code) {
      this.$router.push("/post/" + code);
    },
    getReviewSeller(code) {
      memberService.getReviewSeller(code).then((res) => {
        this.seller = res.data.reviews;
        this.rate_seller = parseFloat(res.data.star) || 0;
        this.total_seller = res.data.total;
      });
    },
    getReviewBuyer(code) {
      memberService.getReivewBuyer(code).then((res) => {
        this.buyer = res.data.reviews;
        this.rate_buyer = parseFloat(res.data.star) || 0;
        this.total_buyer = res.data.total;
      });
    },
    getPostBy(code) {
      postService.getPostBy(code).then((res) => {
        this.posts = res.data;
        // this.rate_buyer = parseFloat(res.data.star) || 0;
        // this.total_buyer = res.data.total;
      });
    },
    getPostWinBy(code) {
      postService.getPostWinBy(code).then((res) => {
        this.postsWin = res.data;
        // this.buyer = res.data.reviews;
        // this.rate_buyer = parseFloat(res.data.star) || 0;
        // this.total_buyer = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  padding: 20px;
  background-color: #f1f1f1;
  margin: 100px 20px 20px 20px;

  border-radius: 20px;

  display: flex;
  .img {
    margin-right: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50% 50%;
    // align-self: flex-start;
  }
  .text {
    flex: 1 1 auto;
  }
}
.content {
  // min-height: 500px;
  display: unset !important;
  background-color: transparent;
}
</style>
