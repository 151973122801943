<template>
  <div class="vl-parent">
    <!-- <loading
      v-model="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    /> -->

    <!-- <label><input type="checkbox" v-model="fullPage" />Full page?</label> -->
    <!-- <button @click.prevent="doAjax">fetch Data</button> -->
  </div>
</template>

<script>
import axios from "axios";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "line-login",
  data() {
    return {
      isLoading: true,
      fullPage: true,
      code: "",
      token: "",
      dat: "",
    };
  },
  // components: {
  //   Loading,
  // },
  async created() {
    // const { code } = queryString.parse(window.location.search.replace("?", ""));
    this.code = this.$route.query.code;

    // this.dat = await this.getUser(this.code);

    this.token = await this.getToken(this.code);

    await this.verify(this.token.access_token);
    // let assces_token = this.dat.data.access_token;

    // this.isLoading = false;

    this.$router.push("/");
    // window.opener.location.reload();
    // window.close()
  },
  methods: {
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    async getToken(code) {
      const params = new URLSearchParams({
        code: code,
        grant_type: "authorization_code",
        client_id: "1657686901",
        client_secret: "7fc566df13f04ba791a906ecd35eb2e4",
        redirect_uri: process.env.VUE_APP_LINE_REDIRECT,
        code_verifier: this.makeid(42),
      });

      const { data } = await axios.post(
        "https://api.line.me/oauth2/v2.1/token",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      return data;
      //   return new OAuthResult(data);
    },
    async verify(access_token) {
      await axios
        .get(process.env.VUE_APP_BASE_URI + "auth/verify/line", {
          params: { access_token: access_token },
        })
        .then((res) => {
          console.log("set token");
          console.log(res);
          // alert("get");
          localStorage.setItem("token", res.data.access_token);

          this.$store.dispatch("Account/setDetail", this.res.data);
          this.$store.dispatch("Account/showLogin", false);
        })
        .catch((err) => {});
    },
  },
};
</script>

<style></style>
