<template>
  <div id="main-content">
    <div class="product-box">
      <div class="row">
        <div class="col">1</div>
        <div class="col">2</div>
        <div class="col">3</div>
        <div class="col">4</div>
        <div class="col">5</div>
        <div class="col">6</div>
        <div class="col">7</div>
        <div class="col">8</div>
      </div>

      <div class="row" v-for="index in 10" :key="index">
        <div class="col">1</div>
        <div class="col">
          <img src="@/assets/images/goat/goat001.png" width="100px" />
        </div>
        <div class="col">3</div>
        <div class="col">4</div>
        <div class="col">5</div>
        <div class="col">6</div>
        <div class="col">7</div>
        <div class="col">8</div>
      </div>
      <!-- <table width="100%">
        <tr>
          <th>รายการ</th>
          <th>รูปภาพ</th>
          <th>รายละเอียด</th>
          <th>จำนวน</th>
          <th>รายการ</th>
          <th>รายการ</th>
        </tr>
        <tr v-for="index in 10" :key="index">
          <td>1</td>
          <td><img :src="@"></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>-->
      <!-- <div class="item row" v-for="index in 10" :key="index">
        <div class="col-1">1</div>
        <div class="col-1">
          <img :src="'@/assets/images/goat/goat001.png'" />
        </div>
        <div class="col-3">แพะแม่พันธุ์</div>
        <div class="col-3">4</div>
        <div class="col-1">5</div>
        <div class="col-1">6</div>
        <div class="col-1">7</div>
        <div class="col-1">8</div>
      </div>-->
    </div>
  </div>
</template>


<script>
export default {
  name: "cart"
};
</script>

<style lang="scss" scoped>
.product-box {
  //   margin-top: 30px;
  //   height: 600px;
  background-color: white;

  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;

  //   overflow: auto;

  .item {
    height: 80px;
    // border: 1px solid gray;
    margin-bottom: 5px;
  }
}

.col-8 {
  width: 12.5%;
}
</style>