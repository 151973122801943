import HTTP from "./http-common";

export default {
  addComment(code, comment, parent) {
    let data = {
      code: code,
      comment: comment,
      parent: parent,
    };
    return HTTP.post("post/" + code + "/comment", data)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  getComment(code) {
    return HTTP.get("post/" + code + "/comment")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
};
