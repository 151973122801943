<template>
  <div>
    <Header></Header>
    <div style="margin-top:80px">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  components: {
    Header,
    Footer
  }
};
</script>
