<template>
  <div>
    <h4>ประมูลของฉัน</h4>
    <ul class="list-group">
      <li v-for="(item, idx) in MyPost" :key="idx">
        <!-- @click="open(item.post_code)" -->
        <div class="card product">
          <div class="card-header">
            <div class="column3">
              <div>
                <strong>วันที่ประมูล</strong> :
                {{ item.created_at | cvDateTime }}
              </div>
              <div></div>
              <div>
                <i class="fas fa-eye" alt="ดู" title="ดู"></i> {{ item.views }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="container-img"
              style="cursor:pointer"
              @click="open(item.post_code)"
            >
              <img
                class="image"
                v-if="item.title_img"
                :src="base_img + item.title_img"
                height="100px"
              />
              <img
                class="image"
                v-else
                src="@/assets/images/no-image.png"
                height="80px"
              />
              <div class="text">
                <div class="column3">
                  <div>
                    <span>{{ item.title }}</span>
                  </div>
                  <div>
                    <span class="closed" v-if="item.status == 'close'"
                      >ปิดประมูล</span
                    >
                    <div v-if="item.close_type == 'price'">
                      <span>ปิดโดยราคา</span>
                      <br />
                      <span class="close-price">{{
                        item.price_close | addComma
                      }}</span>
                    </div>

                    <div v-if="item.close_type == 'time'">
                      <span>ปิดโดยเวลา</span><br />
                      <span>{{ cvDate(item.close_time) }}</span>
                    </div>
                  </div>
                  <div class="last-price">
                    <span class="mini-head">ราคาล่าสุด: </span>
                    <span class="price">{{ item.last_price | addComma }}</span>
                    <!-- <div class="up">
                      <i class="fas fa-sort-up"></i>
                      {{ item.last_price - item.price_open }}
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="column3">
              <div>
                &nbsp;
                <span class="mini-head">ราคาเปิด </span>
                <span class="open-price">{{ item.price_open | addComma }}</span>
                |

                <span class="mini-head">ราคาเคาะ </span>
                <span class="close-price">{{
                  item.price_step | addComma
                }}</span>
              </div>
              <div></div>
              <div>
                <span v-if="item.count_bids == 0">
                  <!-- <router-link to @click.native.stop="editPost(item.post_code)"
                    ><i class="fas fa-edit action-icon"></i>&nbsp;<span
                      class="action"
                      >ขายแล้ว</span
                    ></router-link
                  >
                  | -->
                  <router-link to @click.native.stop="editPost(item.post_code)"
                    ><i class="fas fa-edit action-icon"></i>&nbsp;<span
                      class="action"
                      >แก้ไข</span
                    ></router-link
                  >
                  |
                  <router-link to @click.native.stop="deletePost(item)"
                    ><i class="fas fa-trash action-icon"></i>&nbsp;
                    <span class="action">ลบ</span>
                  </router-link>
                </span>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import postService from "@/services/posts";

export default {
  data() {
    return {
      MyPost: [],
    };
  },
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  created() {
    this.getMyPost();
  },
  methods: {
    cvDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getMyPost() {
      postService.myPost().then((res) => {
        this.MyPost = res.data;
      });
    },
    convertDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    open(code) {
      this.$router.push("/post/" + code);
    },
    convertStatus(status) {
      if (status == "close") return "ปิด";
      else if (status == "เปิด") return "เปิด";
      else return "";
    },
    editPost(code) {
      this.$router.push("/member/posts/" + code + "/edit");
    },
    deletePost(item) {
      // this.$router.push("/member/posts/" + code + "/edit");
      let _self = this;
      //console.log(item);
      this.$swal
        .fire({
          title: "ต้องการลบโพสนี้?",
          text: "กรุณายืนยันการลบโพส",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "ลบได้เลย!",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.isConfirmed) {
            postService
              .delete(item.post_code)
              .then((res) => {
                _self.getMyPost();
                _self.$swal.fire({
                  icon: "success",
                  title: "ลบข้อมูลเรียบร้อยแล้ว",
                  text: "โพสจะไม่สามารถนำกลับมาได้",
                });
              })
              .catch((err) => {
                _self.$swal.fire({
                  icon: "error",
                  title: "ผิดพลาด",
                  text: err.response.data,
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h6 {
  margin-top: 10px !important;
}
tr:nth-child(even) {
  background: #f1f1f1;
}
tr:nth-child(odd) {
  background: #fff;
}
tr:hover {
  background: #fdf7d4;
}

@media (max-width: 855px) {
  table,
  tbody,
  tr,
  td {
    display: block;
  }
  table {
    border-top: 1px solid #e8e8e8; /* optional */
  }
  thead {
    display: none;
  }
  tr:nth-child(odd) {
    background-color: #f9f9f9; /* optional */
  }
  td {
    position: relative;
    text-align: left;
    padding: 7px 13px; /* optional */
    padding-left: calc(50% + 13px); /* 50% is arbitrary, 13px is optional */
    border: 1px solid #e8e8e8; /* optional */
    border-top: none; /* optional */
  }
  td:before {
    content: attr(data-label);
    position: absolute;
    white-space: nowrap;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%; /* arbitrary */
    text-align: left;
    padding: 7px 13px; /* optional */
    font-weight: 600; /* optional */
    border-right: 1px solid #e8e8e8; /* optional */
  }
}
/* Styles for normal mode (all are optional, presentational styles) */
@media (min-width: 856px) {
  th {
    white-space: nowrap;
    font-weight: 600;
  }
  th,
  td {
    padding: 7px 13px;
    border: 1px solid #e8e8e8;
  }
  th:not(.align-start),
  td:not(.align-start) {
    text-align: center;
  }
}

.list-group {
  width: 100%;
}

.product {
  list-style: none;
  // border: 1px solid gray;
  border-radius: 5px;
  // padding: 10px;
  margin-bottom: 10px;

  .card-header {
    padding: 5px;
    font-size: 14px;
  }

  .card-body {
    padding: 5px;
  }

  .card-footer {
    padding: 5px;
    font-size: 14px;
    background: none;
  }
}
// .panel-img,
// .panel-info,
// .panel-rating,
// .panel-more1 {
//   float: left;
//   margin: 0 10px;
// }

.column3 {
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  height: 100%;
  div {
  }
}
.mini-head {
  color: gray;
}

.container-img {
  display: flex;
  .image {
    width: 100px;
    object-fit: contain;
    align-self: flex-start;
    margin-right: 10px;
  }
  .text {
    flex: 1 1 auto;
    position: relative;
    // position: fixed;
  }
}
.last-price {
  font-size: 24px;
  position: absolute;
  // position: fixed;
  // vertical-align: text-bottom;
  bottom: 0;
  right: 0;
  .price {
    color: goldenrod;
    bottom: 0;
  }
  .up {
    text-align: right;
    color: green;
    font-size: 14px;
  }
}
.open-price {
  color: green;
}
.close-price {
  color: red;
}
.action-icon {
  color: goldenrod;
}
.action {
  color: black;
}
.card-footer {
  border: none;
}
.closed {
  background-color: red;
  color: white;
  font-size: 11px;
  padding: 3px 10px;
  border-radius: 10px;
}
</style>
