<template>
  <section class="section-box">
    <div class="container">
      <div class="row">
        <div class="col-3">
          <div class="form-group select-style select-style-icon">
            <!-- <select class="form-control form-icons select-active">
                    <option>จังหวัด</option>
                  </select> -->
            <!-- <select class="form-control">
              <option selected>ทั้งหมด</option>
              <option
                :value="item.id"
                v-for="(item, pidx) in provinces"
                :key="pidx"
                >{{ item.name_th }}</option
              >
            </select> -->
            <i class="fi-rr-marker"></i>
          </div>
          <h5 class="medium-heading mb-15">รูปแบบประมูล</h5>
          <ul class="list-checkbox">
            <li>
              <div class="radio icheck-sunflower">
                <input type="radio" id="type1" name="type" checked />
                <label for="type1">ทั้งหมด</label>
              </div>
            </li>
            <li>
              <div class="radio icheck-sunflower">
                <input type="radio" id="type2" name="type" />
                <label for="type2">ปิดโดยวัน</label>
              </div>
            </li>
            <li>
              <div class="radio icheck-sunflower">
                <input type="radio" id="type3" name="type" />
                <label for="type3">ปิดโดยราคา</label>
              </div>
            </li>
          </ul>
          <h5 class="medium-heading mb-15">สถานะประมูล</h5>
          <ul class="list-checkbox">
            <li>
              <div class="radio icheck-sunflower">
                <input type="radio" id="status1" name="status" checked />
                <label for="status1">ทั้งหมด</label>
              </div>
            </li>
            <li>
              <div class="radio icheck-sunflower">
                <input type="radio" id="status2" name="status" />
                <label for="status2">เปิดประมูล</label>
              </div>
            </li>
            <li>
              <div class="radio icheck-sunflower">
                <input type="radio" id="status3" name="status" />
                <label for="status3">ปิดประมูล</label>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-9">
          <h4>คำค้น : {{ this.keyword }}</h4>
          <!-- <div v-for="(item, idx) in result" :key="idx">{{ item }}</div>
           -->
          <div class="grid-container" v-if="result.length > 0">
            <div
              class="card-product"
              v-for="(item, nidx) in result"
              :key="'new' + nidx"
              @click="goToDetail(item.post_code)"
            >
              <div class="img-post">
                <img
                  v-if="item.title_img && !null"
                  :src="base_img + item.title_img"
                  alt="รูป"
                />
                <img
                  v-else
                  src="@/assets/images/noimage.png"
                  alt="ไม่พบรูปภาพ"
                  width="100%"
                />
                <div class="close-label" v-if="item.status == 'close'">
                  ปิดประมูล
                </div>
                <div class="sell-label" v-else-if="item.status == 'sell'">
                  ขายแล้ว
                </div>
                <!-- <div
            class="favorite"
            @click.stop="onLike(item)"
            :class="{ active: checkFavorite(item.id) }"
          >

          </div> -->
              </div>
              <div class="detail">
                <!-- <div class="views">ดู {{ item.views }}</div> -->
                <div class="title">{{ item.title }}</div>
                <div class="province">{{ item.province_name }}</div>
                <div class="price">
                  <div>
                    <i
                      class="fas fa-certificate verify"
                      v-if="item.verify == true"
                    ></i>
                    {{ item.display_name }}
                  </div>
                  <!-- <div >ราคาเปิด <span class="open">{{ item.price_open | addComma }}</span></div> -->
                  <div class="last">
                    ราคาปัจจุบัน
                    <span class="price">{{ item.last_price | addComma }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-post">ไม่พบข้อมูล</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import provinceService from "../services/Province";
import postService from "../services/posts";
export default {
  name: "search-page",
  watch: {
    $route(to) {
      // this.show = false;
      // console.log(to);
      let query = to.query;
      // this.keyword = key;
      console.log("secound", query);

      this.keyword = query.keyword;

      this.search(this.keyword);
      // this.search(key);
      //console.log("watch",key);
    },
    // keyword(data) {
    //   console.log(data);
    // },
  },
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  data() {
    return {
      keyword: "",
      province_id: null,
      provinces: [],
      result: [],
    };
  },
  created() {
    this.getProvince();
    let query = this.$route.query;
    this.keyword = query.keyword;
    this.search(this.keyword);
  },
  methods: {
    getProvince() {
      provinceService.list().then((res) => {
        this.provinces = res.data;
      });
    },
    search(keyword) {
      if (keyword == "") {
        this.result = [];
        return;
      }
      postService
        .search(keyword)
        .then((result) => {
          this.result = result.data;
        })
        .catch({});
    },
    goToDetail(code) {
      this.$router.push("/post/" + code);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/icheck-bootstrap.min.css";

.section-box {
  padding-top: 20px !important;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #e0e6f7;
  border-radius: 7px;
}

.cb-container input:checked ~ .checkmark {
  border: 2px solid #3c65f5;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cb-container input:checked ~ .checkmark:after {
  display: block;
}

.cb-container .checkmark:after {
  left: 0px;
  top: -1px;
  width: 21px;
  height: 22px;
  border-radius: 5px;
  background: #3c65f5 url(../assets/tick.svg) no-repeat center;
}
</style>
