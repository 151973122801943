<template>
  <div id="file-drag-drop" class="box-upload">
    <div class="box-file" ref="fileform">
      <span class="drop-files" v-if="files.length < 0"
        >Drop the files here!</span
      >
      <input
        ref="input-file"
        type="file"
        multiple="multiple"
        style="display:none;"
        accept="image/x-png, image/gif, image/jpeg"
        @change="selectFile"
      />
      <!-- <div
        class="box-image file-listing"
        style="float:left:text-align:center"
        @click.stop="addFile()"
      >
        <img :src="require('@/assets/upload.png')" width="80%" height="80%" />
      </div>-->
      <div
        class="box-image file-listing"
        style="float:left:text-align:center;float:left"
        @click.stop="addFile()"
      >
        <img :src="require('@/assets/upload.png')" width="80%" height="80%" />
      </div>
      <draggable
        v-model="files"
        group="people"
        @end="reDraw"
        @sort="onSort"
        @onUpdate="onUpdate"
      >
        <div
          class="box-image file-listing"
          v-for="(file, key) in files"
          :key="key"
        >
          <img class="preview" :ref="'preview' + parseInt(key)" />
          <div class="image-action">
            <!-- <a class="rotate" @click="rotateImg(key)">
              <i class="fas fa-redo"></i>
            </a>-->
            <a class="remove" v-on:click="removeFile(key)">
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      </draggable>
      <!-- <ul class="box-list-preview">
        <li class="file-listing">
          <span class="btn-add-image" style="float:left" @click="addFile();">
            <font-awesome-icon
              icon="plus"
              size="3x"
              style="margin-left:22px;margin-top:25px;color:gray;"
            />
          </span>
      </li>-->

      <div class="total-image">{{ files.length }}/{{ limitUpload }}</div>
    </div>
    <!-- <draggable v-model="files" group="people">
        <div v-for="element in files" :key="element.id">{{element.name}}</div>
    </draggable>-->
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["listFile"],
  components: {
    draggable,
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  watch: {
    listFile(data) {
      console.log("img", data);
      let i = 1;
      data.forEach((item) => {
        this.files.push({
          id: item.id,
          rotate: 0,
          sort: i++,
          image: this.base_img + item.img_path,
        });
      });

      this.getImagePreviews();

      this.onUpdate();
    },
  },
  data() {
    return {
      limitUpload: 5,
      dragAndDropCapable: false,
      files: [],
      filesDelete: [],
      uploadPercentage: 0,
      enabled: true,
    };
  },

  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if (this.dragAndDropCapable) {
      [
        // "drag",
        //"dragstart",
        "dragend",
        "dragover",
        // "dragenter",
        // "dragleave",
        "drop",
      ].forEach(
        function(evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      /*
          Add an event listener for drop to the form
        */
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          if (this.checkLimitFile(e.dataTransfer.files.length) == false) return;

          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            // e.dataTransfer.files[i]["rotate"] = 0;
            // this.files.push(e.dataTransfer.files[i]);

            this.files.push({
              id: null,
              rotate: 0,
              sort: this.files.length + 1,
              image: e.dataTransfer.files[i],
            });
            // this.filesRotate.push(0);

            // this.filesGroup.push(
            //   {file : e.dataTransfer.files[i], rotate: 0}
            // );
          }

          this.getImagePreviews();

          this.onUpdate();
        }.bind(this)
      );
    }
  },
  created() {
    // if (this.listFile.length > 0) {
    //   this.listFile.forEach(item => {
    //     this.files.push(this.base_img + item);
    //   });
    //   this.getImagePreviews();
    // }
  },
  methods: {
    checkLimitFile(newFiles) {
      if (this.files.length + newFiles > this.limitUpload) {
        // alert("จำนวนสูงสุดได้ไม่เกิน "+this.limitUpload+" ภาพ");
        this.$swal("จำนวนรูปสูงสุดได้ไม่เกิน " + this.limitUpload + " ภาพ");
        return false;
      } else {
        return true;
      }
    },

    /*
        Determines if the drag and drop functionality is in the
        window
      */
    determineDragAndDropCapable() {
      var div = document.createElement("div");

      return (
        "ondragstart" in div &&
        "ondrop" in div &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].image.name)) {
          let reader = new FileReader();

          reader.addEventListener(
            "load",
            function() {
              this.$nextTick(function() {
                this.$refs["preview" + parseInt(i)][0].src = reader.result;
              });
            }.bind(this),
            true
          );

          reader.readAsDataURL(this.files[i].image);
        } else {
          this.$nextTick(function() {
            this.$refs["preview" + parseInt(i)][0].src = this.files[i].image; //"/images/file.png";
          });
        }
      }
    },

    selectFile(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (this.checkLimitFile(files.length) == false) return;

      if (!files.length) return;
      for (var i = files.length - 1; i >= 0; i--) {
        this.files.push({
          id: null,
          rotate: 0,
          sort: this.files.length + 1,
          image: files[i],
        });
        // Object.defineProperty(files[i], "id", {
        //   value: null,
        //   writable: false
        // });
        // Object.defineProperty(files[i], "test", {
        //   value: 42,
        //   writable: false
        // });
      }

      this.getImagePreviews();

      this.onUpdate();

      this.$refs["input-file"].value = "";
    },

    removeFile(key) {
      // this.filesGroup.splice(key, 1)
      if (this.files[key].id != null) {
        this.filesDelete.push(this.files[key].id);
      }
      this.files.splice(key, 1);

      let i = 1;
      this.files.forEach((item) => {
        item.sort = i++;
      });

      this.getImagePreviews();
      this.onUpdate();
    },
    rotateImg(key) {
      this.filesRotate[key] = this.filesRotate[key] + 90;
      // if (this.filesRotate[key] > 360) this.filesRotate[key] = 0;

      this.$refs["preview" + key][0].style.transform =
        "rotate(" + this.filesRotate[key] + "deg)";
      this.$refs["preview" + key][0].style.transition = "0.5s";

      this.onUpdate();
    },
    onUpdate() {
      this.$emit("onUpdate", {
        files: this.files,
        filesDelete: this.filesDelete,
      });

      // console.log("file", this.files);
    },
    addFile() {
      this.$refs["input-file"].click();
    },
    reDraw() {
      // console.log("re render");

      this.getImagePreviews();
      this.$forceUpdate();
      // console.log(this.files);
    },
    onSort() {
      //console.log("on sort");
      let i = 1;
      this.files.forEach((item) => {
        // console.log(item)
        // console.log(i++);
        item.sort = i++;
      });

      this.onUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-file {
  display: block;
  height: 300px;
  width: 100%;
  background: #ffffff;
  margin: auto;
  border-radius: 4px;
  border: 1px dashed gray;
  overflow: auto;
  position: relative;

  ul {
    margin: 0px;
    padding: 0px;
  }

  ul li {
    list-style: none;
    display: inline-block;
  }

  .btn-add-image {
    padding: 5px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    background-color: white;
    height: 150px;
    width: 120px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
}

.image-action {
  text-align: center;
  // display:inline;

  .rotate {
    float: left;
    cursor: pointer;
  }

  .remove {
    float: right;
    cursor: pointer;
  }
}

.box-image {
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  display: inline-block;
  background-color: white;
  cursor: move;
  img {
    height: 100px;
    width: 100px;
    border-radius: 3px;
  }
  margin: 5px;
}

.box-list-preview {
  display: inline-block;
}
.box-upload {
  display: flex;
  flex-wrap: wrap;
}
.total-image {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 5px 0 5px;
  background: #c7c7c7;
  margin: 3px;
  border-radius: 5px;
}
</style>
