import HTTP from "./http-common";

export default {
  seller(form) {
    return HTTP.post("member/review/seller", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  buyer(form) {
    return HTTP.post("member/review/buyer", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  // getRateSeller(form) {
  //   return HTTP.get("member/review/seller")
  //     .then(function(response) {
  //       return Promise.resolve(response);
  //     })
  //     .catch(function(error) {
  //       return Promise.reject(error);
  //       // console.log(error);
  //     });
  // },
  // getRateBuyer(form) {
  //   return HTTP.get("member/review/buyer")
  //     .then(function(response) {
  //       return Promise.resolve(response);
  //     })
  //     .catch(function(error) {
  //       return Promise.reject(error);
  //       // console.log(error);
  //     });
  // },
};
