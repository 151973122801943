<template>
  <div id="app">
    <!-- <Header></Header> -->
    <router-view />
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// import Header from "./components/Header";
// import Footer from "./components/Footer";

// import AccountService from "./services/Accounts";

export default {
  // components: {
  //   Header,
  //   Footer
  // },
  async created() {
    // let token = localStorage.getItem("token");
    // if (token) {
    //   let me = await AccountService.me();
    //   // console.log(me);
    //   // this.$store.commit('')
    //   this.$store.dispatch("Account/setDetail", me.data);
    // }
  },
  // async beforeRouteEnter(to, from, next) {
  //   console.log("first load", to, from);
  //   next();
  // }
  // beforeRouteEnter(to, from, next) {
  //   console.log(to, from);
  //   // site = await getSite(to.params.siteId)
  //   next();
  // }
};
</script>

<style>
#app {
  background-color: #f2f2f2;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
