import HTTP from './http-common';

export default {
  all() {
    return HTTP.get('favorite/all')
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  list() {
    return HTTP.get('favorite')
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  add(post_id) {
    return HTTP.post('favorite', { post_id: post_id })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
};
