<template>
  <div></div>
</template>

<script>
import AccountService from "../services/Accounts";

export default {
  name: "activate",
  created() {
    let email = this.$route.query.email;
    let key = this.$route.query.key;
    // alert(email + key)
    AccountService.activate(email, key)
      .then(res => {
        // console.log(res);
        this.$swal({
          title: res.data.message,
          text: "สามารถล๊อกอินเข้าระบบได้ปกติ",
          type: "success"
        }).then(() => {
          this.$router.push("/");
        });
        //
      })
      .catch(err => {
        this.$swal(err.response.data.message).then(() => {
          this.$router.push("/");
        });
      });
  }
};
</script>