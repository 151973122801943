// import Accounts from "@/services/Accounts";
// import { reject } from "q";

// const LOGIN = "LOGIN";
// const LOGIN_SUCCESS = "LOGIN_SUCCESS";
// const LOGOUT = "LOGOUT";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("token"),
    isLoggedIn: false,
    detail: {},
    showLogin: false,
    showRegister: false,
  },
  getters: {
    isLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    detail: (state) => {
      return state.detail;
    },
    info: (state) => {
      return state.detail;
    },
    showLogin: (state) => {
      return state.showLogin;
    },
    showRegister: (state) => {
      return state.showRegister;
    },
  },
  mutations: {
    setDetail: (state, detail) => {
      state.detail = detail;
      state.isLoggedIn = true;
    },
    setLogin: (state) => {
      state.isLoggedIn = true;
    },
    setLogout: (state) => {
      state.isLoggedIn = false;
      state.detail = {};
    },
    setShowLogin: (state, show) => {
      state.showLogin = show;
    },
    setShowRegister: (state, show) => {
      state.showRegister = show;
    },
  },
  actions: {
    checkLogin({ commit }) {
      let token = localStorage.getItem("token");

      if (token) {
        //console.log('has token');
      } else {
        //console.log('no have token');
        commit("setShowLogin", true);
      }
    },
    setDetail({ commit }, detail) {
      commit("setDetail", detail);
    },
    login({ commit }) {
      commit("setLogin");
    },
    logout({ commit }) {
      commit("setLogout");
    },
    showLogin({ commit }, show) {
      commit("setShowLogin", show);
    },
    showRegister({ commit }, show) {
      commit("setShowRegister", show);
    },
  },
};
