import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

import VueSweetalert2 from "vue-sweetalert2";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import VueGoogleSigninButton from "vue-google-signin-button";

// import Buefy from "buefy";

import "./assets/app.scss";

// If you don't need the styles, do not connect

// import { BootstrapVue } from "bootstrap-vue";

import Filters from "./filters";

// Vue.use(VueGoogleSigninButton);

// import filters from './filters';
for (let name in Filters) {
  Vue.filter(name, Filters[name]);
}

// Vue.use(Buefy);

Vue.use(require("vue-prevent-parent-scroll"));
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// library.add(faHeart, faShoppingCart);

// Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueSweetalert2);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Vue.use(ElementUI);
// Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin);

// Vue.config.productionTip = false;

// import GoogleAuth from "@/config/google_oAuth.js";
// const gauthOption = {
//   clientId:
//     "147385082977-vn0sb8odkb1b5hfk1a2satqk8sb4e5cg.apps.googleusercontent.com",
//   scope: "profile email",
//   prompt: "select_account",
// };
// Vue.use(GoogleAuth, gauthOption);
Vue.config.productionTip = false;

Vue.filter("cvDateTime", (date) => {
  return moment(date).format("DD/MM/YYYY HH:mm");
});

new Vue({
  router,
  // Filters,
  store,
  render: (h) => h(App),
}).$mount("#app");
