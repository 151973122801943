<template>
  <div class="container" style="padding-top:20px;">
    <div class="main-body">
      <div class="row gutters-sm">
        <div class="col-md-3 mb-3">
          <div>
            <div>
              <div class="d-flex flex-column align-items-center text-center">
                <img
                  :src="account.image"
                  alt="Admin"
                  class="rounded-circle"
                  style="background-color:white;padding:0px;"
                  width="80"
                />
                <div class="mt-3">
                  <h5>{{ account.display_name }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="profile-work">
              <p>ประมูล</p>
              <router-link class="nav-link" to="/member/posts"
                >ของฉัน</router-link
              >
              <router-link class="nav-link" to="/member/buy-sale"
                >ซื้อ-ขาย</router-link
              >

              <p>ระบบ</p>
              <router-link class="nav-link" to="/member/profile"
                >ข้อมูลส่วนตัว</router-link
              >
              <!-- <router-link class="nav-link" to="/member/posts"
                >ที่อยู่จัดส่ง</router-link
              > -->
              <!-- <p>ระบบ</p> -->
              <!-- <router-link class="nav-link" to="/member/posts"
                >เปลี่ยนรหัสผ่าน</router-link
              > -->
              <router-link class="nav-link" @click.native="logOut" to
                >ออกจากระบบ</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="mb-3"><router-view></router-view></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "member-layout",
  computed: {
    account() {
      return this.$store.getters["Account/detail"];
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("Account/logout").then(() => {
        localStorage.removeItem("token");
        this.$router.push("/");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  // background-color: white;
  border-radius: 5px;
}
// .navbar {
//   background-color: white;
//   border-radius: 0 0 10px 10px;
//   margin-bottom: 10px;
//   margin-top: 30px;
//   .nav-item.active {
//     background: gold;
//     border-radius: 5px;
//   }
// }

// .topnav {
//   overflow: hidden;
//   background-color: white;
// }

// .topnav a {
//   float: left;
//   color: #000000;
//   text-align: center;
//   padding: 14px 16px;
//   text-decoration: none;
//   font-size: 17px;
// }

// .topnav a:hover {
//   background-color: #ddd;
//   color: black;
// }

// .topnav a.active {
//   // background-color: #4CAF50;
//   color: white;
// }
.user-menu {
  display: flex;
  flex-direction: column;
  // background-color: white;
  // margin-top:10px;
  border-radius: 5px;
  // border: 1px solid #dbdbdb;
}

.menu {
  max-width: 400px;
  margin: 20px auto;
  line-height: 24px;

  a {
    display: block;
    cursor: pointer;
    color: #44f;
  }
}

@media only screen and (max-width: 766px) {
  .user-menu {
    display: inline-flex;
    align-items: flex-start;
    flex-direction: row;
    border: none;
    li {
      margin: 0 5px 5px 0;
      border: 1px solid #dbdbdb;
      border-radius: 3px;
    }
  }
}

.profile-work {
  padding: 14%;
  margin-top: -10%;

  p {
    color: black !important;
    font-weight: bold;
  }
  .active {
    color: red;
  }
}
.profile-work p {
  font-size: 18px;
  color: #818182;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
  // margin-top: 5%;
  // padding: 0px !important;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  // font-weight: 600;
  font-size: 16px;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
}
.profile-work ul {
  list-style: none;
}
</style>
