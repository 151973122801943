import HTTP from "./http-common";

export default {
  login(form) {
    return HTTP.post("login", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },

  register(form) {
    return HTTP.post("register", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },

  me() {
    return HTTP.get("me")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  refreshToken() {},
  loginFacebook(params) {
    return HTTP.post("login-facebook", params)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  loginGoogle(params) {
    return HTTP.post("login-google", params)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  activate(email, key) {
    return HTTP.get("activate", { params: { email: email, key: key } })
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  // getProfile() {
  //   return HTTP.get('profile')
  //     .then(function(response) {
  //       return Promise.resolve(response);
  //     })
  //     .catch(function(error) {
  //       return Promise.reject(error);
  //       // console.log(error);
  //     });
  // },
  updateProfile(form) {
    return HTTP.put("profile", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  getAllPost($member_id) {
    return HTTP.get("member/" + $member_id + "/posts")
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
};
