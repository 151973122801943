<template>
  <div>
    <div class="grid-container" v-if="newPost.length > 0">
      <div
        class="card-product"
        v-for="(item, nidx) in newPost"
        :key="'new' + nidx"
        @click="goToDetail(item.post_code)"
      >
        <div class="img-post">
          <img
            v-if="item.title_img && !null"
            :src="base_img + item.title_img"
            alt="รูป"
          />
          <img
            v-else
            src="@/assets/images/noimage.png"
            alt="ไม่พบรูปภาพ"
            width="100%"
          />
          <div class="close-label" v-if="item.status == 'close'">
            ปิดประมูล
          </div>
          <div class="sell-label" v-else-if="item.status == 'sell'">
            ขายแล้ว
          </div>
          <!-- <div
            class="favorite"
            @click.stop="onLike(item)"
            :class="{ active: checkFavorite(item.id) }"
          >

          </div> -->
        </div>
        <div class="detail">
          <!-- <div class="views">ดู {{ item.views }}</div> -->
          <div class="title">{{ item.title }}</div>
          <div class="province">{{ item.province_name }}</div>
          <div class="price">
            <div>
              <i
                class="fas fa-certificate verify"
                v-if="item.verify == true"
              ></i>
              <span class="name">{{ item.display_name }}</span>
              <span class="name">{{ item.name_th }}</span>
            </div>
            <!-- <div >ราคาเปิด <span class="open">{{ item.price_open | addComma }}</span></div> -->
            <div class="last">
              ราคาปัจจุบัน
              <span class="price">{{ item.last_price | addComma }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty-post">
      ยังไม่มีประกาศ
    </div>
  </div>
</template>

<script>
import postService from "../services/posts";

export default {
  name: "new-post",
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  data() {
    return { newPost: [] };
  },
  created() {
    this.getNewPost();
  },
  methods: {
    getNewPost() {
      postService.newPost().then((res) => {
        this.newPost = res.data;
      });
    },
    checkFavorite(post_id) {
      // if (this.favorites.includes(post_id)) return true;
      // else false;
    },
    goToDetail(code) {
      this.$router.push("/post/" + code);
    },
  },
};
</script>

<style lang="scss" scoped>
.verify {
  color: green;
}
.name {
  color: goldenrod;
  font-size: 12px;
}
</style>
