<template>
  <div style="position:relative" @mouseleave="show = false">
    <input class="form-control" type="text" :value="value" @input="handleInput" @click="showOld"/>
    <ul class="history" v-if="show">
      <li v-for="(item, idx) in matches" :key="'al' + idx" @click="selectOld(item.title)">
        <span>{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "input-auto",
  props: ["value","history","last"],
  watch:{
    "last":function(value){
      this.matches = value;
    }
  },
  data() {
    return {
        show: false,
      matches: [],
      content: this.value
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
    showOld(){
        this.show =true;
    },
    selectOld(suggestion){
        this.show = false;
        this.$emit("input", suggestion);
    }
  }
};
</script>

<style lang="scss" scoped>
.history{
  position: absolute;
    z-index:100;
    width:100%;
    border:1px solid  #d3d3d3;
    border-radius: 3px;
    background-color:white;
    padding:10px;

    li{
        padding:3px;
        border-radius: 3px;
        cursor: pointer;
    }
    li:hover{
        background-color:rgb(236, 236, 236);
    }
    
}
</style>
