<template>
  <div class="container" style="padding-top:20px;margin-bottom: 50px;">
    <h3>{{ group.cat_name }}</h3>
    <div class="row">
      <div class="grid-container">
        <div
          class="card-product"
          v-for="(item, tidx) in posts"
          :key="'top' + tidx"
          @click="goToDetail(item.post_code)"
        >
          <div class="img-post" style="text-align:center">
            <img :src="base_img + item.title_img" width="120px" />
            <div class="close-label" v-if="item.status == 'close'">
              ปิดประมูล
            </div>
          </div>
          <div class="detail">
            <div class="title">{{ item.title }}</div>
            <div class="province">{{ item.province_name }}</div>
            <div class="price">
              <div class="open">เปิด {{ item.price_open | addComma }}</div>
              <div class="last">
                ราคาปัจจุบัน {{ item.last_price | addComma }}
              </div>
            </div>
            <!-- <div class="parent"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import postService from "../services/posts";
import categoryService from "../services/Category";

export default {
  name: "group",
  watch: {
    $route(to, from) {
      // react to route changes...

      let cat_id = to.params.id;

      this.getInfo(cat_id);
      this.getPost(cat_id);
    },
  },
  data() {
    return {
      group: {},
      posts: [],
    };
  },
  computed: {
    base_img() {
      return process.env.VUE_APP_BASE_IMG_UPLOAD;
    },
  },
  created() {
    let cat_id = this.$route.params.id;

    //console.log(cat_id);

    this.getInfo(cat_id);
    this.getPost(cat_id);
  },
  methods: {
    getInfo(cat_id) {
      categoryService.info(cat_id).then((res) => {
        this.group = res.data;
      });
    },
    getPost(cat_id) {
      postService.getbyCat(cat_id).then((res) => {
        this.posts = res.data;
      });
    },
    goToDetail(code) {
      this.$router.push("/post/" + code);
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 20px;
  color: black;
}

.img-post {
  position: relative;
  // height: 180px;
  // overflow:hidden;
  background-color: #f1f1f1;
  text-align: center;

  .close-label {
    color: white;
    font-size: 12px;
    padding: 0 5px 0 5px;
    background-color: #e40202;
    position: absolute;
    top: 8px;
    right: 10px;
    border-radius: 5px;
    width: 70px;
    text-align: center;
  }
  .favorite {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: #ffffff80;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
  }
  .active,
  .favorite:hover {
    background: gold;
  }
}
</style>
