import HTTP from "./http-common";

export default {
  list(post_code) {
    return HTTP.get("bids/" + post_code)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  bid(form) {
    return HTTP.post("bid", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
  check(form) {
    return HTTP.post("bid/check", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
      });
  },
  fight(form) {
    return HTTP.post("bid/fight", form)
      .then(function(response) {
        return Promise.resolve(response);
      })
      .catch(function(error) {
        return Promise.reject(error);
        // console.log(error);
      });
  },
};
