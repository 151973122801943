<template>
  <div class="row">
    <div class="col-md-12">
      <div class="comments">
        <h3 class="title">ความคิดเห็น</h3>
        <div v-if="isLogined">
          <div class="bg-light p-2">
            <div class="d-flex flex-row align-items-start">
              <img class="rounded-circle" :src="account.image" />
              <textarea
                class="form-control ml-1 shadow-none textarea"
                v-model="comment"
                placeholder="พิมพ์ความคิดเห็นที่นี่..."
                @input="resize()"
                ref="textarea"
              ></textarea>
              <div v-if="errors.comment">{{ errors.comment[0] }}</div>
            </div>
            <div class="mt-2 text-right">
              <button
                class="btn btn-primary btn-sm shadow-none btn-comment"
                type="button"
                @click="addComment"
              >
                แสดงความคิดเห็น
              </button>
            </div>
          </div>
        </div>
        <hr />
        <ul class="comments">
          <li class="clearfix" v-for="(item, idx) in listComment" :key="idx">
            <img :src="item.image" class="avatar" alt="" />
            <div class="post-comments">
              <p class="meta">
                {{ cvDateTime(item.created_at) }}
                <router-link :to="'../member/' + item.mem_code + '/view'">{{
                  item.display_name
                }}</router-link>
                <span class="owner" v-if="owner == item.mem_code"
                  >เจ้าของพระ</span
                >
              </p>
              <p>
                {{ item.comment }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import commentService from "@/services/Comment";

export default {
  name: "comment-box",
  props: ["code", "postStatus", "owner"],
  watch: {
    code() {
      // console.log(this.code);
      //console.log(this.getComment)
      this.getComment();
    },
  },
  computed: {
    account() {
      return this.$store.getters["Account/detail"];
    },
    isLogined() {
      return this.$store.getters["Account/isLoggedIn"];
    },
  },
  data() {
    return {
      comment: "",
      listComment: [],
      errors: [],
    };
  },
  created() {
    // console.log(this.code);
    // // console.log('comment', this.postId)
    // this.getComment();
  },
  methods: {
    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "18px";
      element.style.height = element.scrollHeight + "px";
    },
    addComment() {
      // if (this.comment == "") {
      //   this.$swal.fire(
      //     "กรุณาพิมพ์ความคิดเห็น",
      //     "พิมพ์ความคิดเห็นในช่องแสดงความคิดเห็น",
      //     "info"
      //   );
      //   // this.$refs.comment.$el.focus();
      //   return;
      // }
      commentService
        .addComment(this.code, this.comment)
        .then((res) => {
          this.comment = "";
          this.getComment();
        })
        .catch((err) => {
          // console.log("err", err);
          this.errors = err.data;
        });
    },
    getComment() {
      commentService.getComment(this.code).then((res) => {
        this.listComment = res.data;
      });
    },
    cvDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  min-height: 72px;
  padding: 10px;
  resize: none;
  overflow: hidden;
  background-color: white;
}
.comments::before,
.comments::after,
.comments-form::before,
.comments-form::after {
  content: "";
  display: table;
  clear: both;
}

.comments {
  white-space: pre-line;
  .title {
    padding: 0px;
    margin-bottom: 10px;
  }
  // padding-left: 15%;
  // padding-right: 15%;
}

.comments ul {
  list-style-type: none;
  padding: 0;
}

.comments img {
  opacity: 1;
  filter: Alpha(opacity=100);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.comments img.avatar {
  position: relative;
  float: left;
  margin-left: 10px;
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid white;
}

.comments .post-comments {
  border: 1px solid #eee;
  margin-bottom: 20px;
  margin-left: 65px;
  margin-right: 0px;
  padding: 10px 20px;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  color: #6b6e80;
  position: relative;
}

.comments .meta {
  font-size: 13px;
  color: #aaaaaa;
  padding-bottom: 8px;
  margin-bottom: 10px !important;
  border-bottom: 1px solid #eee;
}

.comments ul.comments ul {
  list-style-type: none;
  padding: 0;
  margin-left: 85px;
}

.comments-form {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 40px;
}

.comments h3,
.comments-form h3 {
  margin-bottom: 40px;
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
}

.comments .rounded-circle {
  margin-right: 20px;
  width: 50px;
}

.btn-comment {
  margin-left: 60px;
  background-color: gold !important;
  color: #4a4a4a;
  border: none;
  &:hover {
    background-color: goldenrod !important;
    color: white;
  }
}

.owner {
  background: goldenrod;
  margin-left: 10px;
  padding: 3px 5px;
  font-size: 10px;
  color: white;
  border-radius: 5px;
}
</style>
